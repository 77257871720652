import moment from "moment"

export const ACTIVE = "active"
export const TRIALING = "trialing"
export const CANCELLED = "cancelled"
export const INCOMPLETE = "incomplete"
export const INCOMPLETE_EXPIRED = "incomplete_expired"
export const PAST_DUE = "past_due"
export const UNPAID = "unpaid"

export default {
  isActive(state) {
    switch (state.subscription.status) {
      case ACTIVE:
      case TRIALING:
        return true
      case CANCELLED:
      case INCOMPLETE:
      case INCOMPLETE_EXPIRED:
      case PAST_DUE:
      case UNPAID:
      default:
        return false
    }
  },
  isTrial(state) {
    switch (state.subscription.status) {
      case TRIALING:
        return true
      default:
        return false
    }
  },
  trialDaysRemaining(state) {
    if (state.subscription.trial_end) {
      let now = moment(new Date())
      let trialEnd = moment(state.subscription.trial_end)
      return Math.round(moment.duration(trialEnd.diff(now)).asDays())
    }
    return 0
  },
  isCancellable(state) {
    return !state.subscription.cancel_at_period_end
  },
  hasActivePaymentMethod(state) {
    return !!state.subscription.card
  },
}
