// CSS
import "normalize.css"
import "./styles/main.scss"

// JS
import Vue from "vue"
import ElementUI from "element-ui"
import locale from "element-ui/lib/locale/lang/en"
import axios from "axios"
import Vue2Filters from "vue2-filters"
import * as Sentry from "@sentry/browser"
import * as Integrations from "@sentry/integrations"
import VueCurrencyFilter from "vue-currency-filter"
import VueMomentLib from "vue-moment-lib"
import VueGtm from "vue-gtm"
import App from "./App.vue"
import router from "./router"
import store from "./store"

axios.defaults.withCredentials = true

Vue.config.productionTip = false

Vue.use(ElementUI, { locale })
Vue.use(Vue2Filters)
Vue.use(VueCurrencyFilter, {
  symbol: "",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: true,
})
Vue.use(VueMomentLib)
Vue.use(VueGtm, {
  id: "GTM-5H6SKBJ",
  vueRouter: router,
})

export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: window._env_.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.Vue({ Vue })],
    environment: window._env_.VUE_APP_SENTRY_ENVIRONMENT,
    release: window._env_.VUE_APP_RELEASE_SHA,
  })
}
