import Vue from "vue"
import Router from "vue-router"

import routerConfig from "./router-config"

// Suppressing vue navigation cancelled since we use double redirects
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(Router)

const router = new Router(routerConfig)

export default router
