import { getCompanyAR, updateAR } from "@/api/company"
import { GET_AR, SET_AR, GET_AR_FAILURE } from "../../types"
import HelmNotification, { notifications } from "@/lib/notification"

export default {
  getCompanyAR(context) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario
    context.commit(GET_AR)
    return getCompanyAR(company_uuid, scenario_uuid)
      .then(({ data }) => {
        context.commit(SET_AR, data)
      })
      .catch((error) => {
        context.commit(GET_AR_FAILURE)
        HelmNotification(notifications.AR_LOAD_FAIL)
        throw error
      })
  },
  UPDATE_AR(context, ar) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario
    const oldState = {
      ...context.state.accounts.find((d) => d.uuid === ar.uuid),
    }

    context.commit("SET_ACCOUNTRECEIVABLE", { ar })
    return updateAR(company_uuid, scenario_uuid, ar)
      .then((response) => {
        context.commit("SET_ACCOUNTRECEIVABLE", { ar: response.data })
        context.dispatch("agedBalances/getAgedBalances", null, {
          root: true,
        })
        context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
          root: true,
        })
      })
      .catch((reason) => {
        HelmNotification(notifications.AR_UPDATE_FAIL)
        context.commit("SET_ACCOUNTRECEIVABLE", { ar: oldState })
        // re-throw so we can use this to notify users in components
        throw reason
      })
  },
}
