import mutations from "./mutations"
import getters from "./getters"

const state = {
  open: false,
  tab: "profile",
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
