export default {
  SET_AGED_BALANCES(state, { agedBalances }) {
    state.agedBalances = agedBalances
  },
  SET_AGED_BALANCE(state, { agedBalance }) {
    const index = state.agedBalances.findIndex((d) => d.id === agedBalance.id)
    state.agedBalances.splice(index, 1, agedBalance)
  },
  SET_UPDATED_AGED_BALANCES(state, { agedBalances }) {
    agedBalances.forEach((agedBalance) => {
      const index = state.agedBalances.findIndex((d) => d.id == agedBalance.id)
      state.agedBalances.splice(index, 1, agedBalance)
    })
  },
}
