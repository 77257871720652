import { initialState } from "./index"

export default {
  SET_PLANS(state, payload) {
    state.plans = payload.plans
  },
  SET_SUBSCRIPTION(state, payload) {
    state.subscription = payload.subscription
  },
  SET_INVOICES(state, payload) {
    state.invoices = payload.invoices
  },
  CLEAR_SUBSCRIPTION(state) {
    state.subscription = {}
  },
  RESET_STATE(state) {
    Object.assign(state, { ...initialState })
  },
}
