import store from "@/store"

export const requireAuthenticated = (to, _from, next) => {
  store.dispatch("auth/initialize").then(() => {
    if (!store.getters["auth/isAuthenticated"]) {
      let query = { redirect: to.fullPath }
      if (to.params.token) {
        query.token = to.params.token
      }
      next({
        name: "login",
        query: query,
      })
    } else {
      next()
    }
  })
}

export const requireUnauthenticated = (_to, _from, next) => {
  store.dispatch("auth/initialize").then(() => {
    if (store.getters["auth/isAuthenticated"]) {
      next("/")
    } else {
      next()
    }
  })
}

export async function populateAuth() {
  try {
    await store.dispatch("auth/initialize")
  } catch (_e) {
    // proceed
  }
}

export async function requireAuthenticatedAndSubscription(to, _from, next) {
  await populateAuth()

  if (!store.getters["auth/isAuthenticated"]) {
    next({
      name: "login",
      query: { redirect: to.fullPath },
    })
    return
  }
}

export async function requireAuthenticatedStaff(to, _from, next) {
  await populateAuth()

  if (!store.getters["auth/isAuthenticated"]) {
    next({
      name: "login",
      query: { redirect: to.fullPath },
    })
    return
  } else {
    if (
      !store.getters["permissions/isAdmin"] &&
      !store.getters["permissions/isStaff"]
    ) {
      next({ name: "not-found" })
      return
    }
  }
  next()
}

export async function requireAuthenticatedOrganizationInvite(to, _from, next) {
  store.dispatch("auth/initialize").then(() => {
    if (!store.getters["auth/isAuthenticated"]) {
      next({
        name: "login",
        query: {
          redirect: to.fullPath,
          token: to.params.token,
          organization: "true",
        },
      })
    } else {
      next()
    }
  })
}
