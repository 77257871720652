export default {
  isAdmin(state, getters, rootState) {
    return (
      rootState.profile.profile.level === "owner" ||
      rootState.profile.profile.level === "admin"
    )
  },
  isStaff(state, getters, rootState) {
    return rootState.profile.profile.level === "staff"
  },
  hasEdit(state, getters, rootState) {
    try {
      const active_user = rootState.profile.profile
      const company_user = rootState.company.users.find(
        (d) => d.user.id === active_user.id
      )
      return (
        company_user.permission === "edit" || getters.isAdmin || getters.isStaff
      )
    } catch (error) {
      // company users not populated
      return getters.isAdmin || getters.isStaff
    }
  },
  isApprover(state, getters, rootState) {
    try {
      const active_user = rootState.profile.profile
      const company_user = rootState.company.users.find(
        (d) => d.user.id === active_user.id
      )
      return company_user.can_approve
    } catch (error) {
      // company users not populated
      return false
    }
  },
}
