import {
  getForecastingRules,
  updateForecastingRule,
  createForecastingRule,
  deleteForecastingRule,
  bulkUpdateForecastingRules,
  bulkDeleteForecastingRules,
} from "@/api/forecasting-rules"
import company from "../company/index"

export function processForecastingRules(rule) {
  rule.account_name = rule.account_details.account_name
  if (rule.contact_details !== null)
    rule.company_name = rule.contact_details.company_name
  else rule.company_name = ""
  return rule
}

export default {
  async getForecastingRules(context) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario
    const res = await getForecastingRules(company_uuid, scenario_uuid)
    const rules = res.data.results.map((rule) => processForecastingRules(rule))

    context.commit("SET_FORECASTING_RULES", {
      forecastingRules: rules,
    })
  },
  async updateForecastingRule(context, forecastingRule) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario
    const res = await updateForecastingRule(
      company_uuid,
      scenario_uuid,
      forecastingRule
    )
    context.commit("SET_FORECASTING_RULE", {
      forecastingRule: processForecastingRules(res.data),
    })
    await context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
      root: true,
    })
  },
  async createForecastingRule(context, forecastingRule) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario
    const res = await createForecastingRule(
      company_uuid,
      scenario_uuid,
      forecastingRule
    )
    context.commit("CREATE_FORECASTING_RULE", { forecastingRule: res.data })
    await context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
      root: true,
    })
  },
  async deleteForecastingRule(context, forecastingRule) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario
    const res = await deleteForecastingRule(
      company_uuid,
      scenario_uuid,
      forecastingRule
    )
    context.commit("DELETE_FORECASTING_RULE", {
      forecastingRule: forecastingRule,
    })
    await context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
      root: true,
    })
  },
  async bulkDeleteForecastingRules(context, forecastingRules) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario
    const res = await bulkDeleteForecastingRules(
      company_uuid,
      scenario_uuid,
      forecastingRules
    )
    context.commit("BULK_DELETE_FORECASTING_RULES", {
      forecastingRules,
    })
    await context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
      root: true,
    })
  },
  async bulkUpdateForecastingRules(
    context,
    { forecastingRules, targetScenarios }
  ) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario
    const res = await bulkUpdateForecastingRules(company_uuid, scenario_uuid, {
      forecastingRules,
      targetScenarios,
    })
    context.commit("SET_PARTIAL_FORECASTING_RULES", {
      forecastingRules,
    })
    await context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
      root: true,
    })
  },
}
