import actions from "./actions"
import mutations from "./mutations"

const state = {
  emailCompleted: false,
  emailError: false,
  emailLoading: false,
  resetCompleted: false,
  resetError: false,
  resetLoading: false,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
