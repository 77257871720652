import session from "./session"

export function getScenarioBankAccounts(company_uuid, scenario_uuid) {
  return session.get(
    `/company/${company_uuid}/scenario/${scenario_uuid}/bankaccounts/`
  )
}

export function updateBankAccount(company_uuid, scenario_uuid, bankAccount) {
  return session.put(
    `/company/${company_uuid}/scenario/${scenario_uuid}/bankaccounts/${bankAccount.id}/`,
    bankAccount
  )
}

export function bulkUpdateBankAccounts(
  company_uuid,
  scenario_uuid,
  bankAccounts
) {
  return session.put(
    `/company/${company_uuid}/scenario/${scenario_uuid}/bankaccounts/multiple_update/`,
    bankAccounts
  )
}

export function createBankAccount(company_uuid, scenario_uuid, bankAccount) {
  return session.post(
    `/company/${company_uuid}/scenario/${scenario_uuid}/bankaccounts/create_from_account/`,
    bankAccount
  )
}

export function deleteBankAccount(company_uuid, scenario_uuid, bankAccount) {
  return session.delete(
    `/company/${company_uuid}/scenario/${scenario_uuid}/bankaccounts/${bankAccount.id}/`
  )
}

export function getBankAccounts(company_uuid) {
  return session.get(`/company/${company_uuid}/bankaccounts/`)
}

export function createClearingAccount(company_uuid) {
  return session.post(
    `/company/${company_uuid}/bankaccounts/create_clearing_account/`
  )
}
