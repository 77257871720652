export const ACTIVATION_BEGIN = "ACTIVATION_BEGIN"
export const ACTIVATION_CLEAR = "ACTIVATION_CLEAR"
export const ACTIVATION_FAILURE = "ACTIVATION_FAILURE"
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS"
export const PASSWORD_EMAIL_BEGIN = "PASSWORD_EMAIL_BEGIN"
export const PASSWORD_EMAIL_CLEAR = "PASSWORD_EMAIL_CLEAR"
export const PASSWORD_EMAIL_FAILURE = "PASSWORD_EMAIL_FAILURE"
export const PASSWORD_EMAIL_SUCCESS = "PASSWORD_EMAIL_SUCCESS"
export const PASSWORD_RESET_BEGIN = "PASSWORD_RESET_BEGIN"
export const PASSWORD_RESET_CLEAR = "PASSWORD_RESET_CLEAR"
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE"
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS"
export const REGISTRATION_BEGIN = "REGISTRATION_BEGIN"
export const REGISTRATION_CLEAR = "REGISTRATION_CLEAR"
export const REGISTRATION_FAILURE = "REGISTRATION_FAILURE"
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS"

export const SET_TOKEN = "SET_TOKEN"
export const REMOVE_TOKEN = "REMOVE_TOKEN"

export const GET_COMPANIES = "GET_COMPANIES"
export const SET_COMPANIES = "SET_COMPANIES"
export const GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE"

export const NEW_COMPANY = "NEW_COMPANY"
export const NEW_COMPANY_SUCCESS = "NEW_COMPANY_SUCCESS"
export const NEW_COMPANY_FAILURE = "NEW_COMPANY_FAILURE"

export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL"
export const SET_COMPANY_DETAIL = "SET_COMPANY_DETAIL"
export const GET_COMPANY_DETAIL_FAILURE = "GET_COMPANY_DETAIL_FAILURE"

export const PATCH_COMPANY_DETAIL = "PATCH_COMPANY_DETAIL"
export const PATCH_COMPANY_SUCCESS = "PATCH_COMPANY_SUCCESS"
export const PATCH_COMPANY_FAILURE = "PATCH_COMPANY_FAILURE"

export const GET_AR = "GET_AR"
export const SET_AR = "SET_AR"
export const GET_AR_FAILURE = "GET_AR_FAILURE"

export const GET_AP = "GET_AP"
export const SET_AP = "SET_AP"
export const GET_AP_FAILURE = "GET_AP_FAILURE"

export const GET_ACCOUNT_DETAIL = "GET_ACCOUNT_DETAIL"
export const SET_ACCOUNT_DETAIL = "SET_ACCOUNT_DETAIL"
export const GET_ACCOUNT_DETAIL_FAILURE = "GET_ACCOUNT_DETAIL_FAILURE"

export const PATCH_ACCOUNT_DETAIL = "PATCH_ACCOUNT_DETAIL"
export const PATCH_ACCOUNT_DETAIL_SUCCESS = "PATCH_ACCOUNT_DETAIL_SUCCESS"
export const PATCH_ACCOUNT_DETAIL_FAILURE = "PATCH_ACCOUNT_DETAIL_FAILURE"

export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE"

export const SET_FIRST_AUTH_INIT = "SET_FIRST_AUTH_INIT"
