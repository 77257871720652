import HelmNotification, { notifications } from "@/lib/notification"
import { toggleFavourite, getDashboardCompanies } from "@/api/dashboard"
import { Loading } from "element-ui"

export default {
  toggleFavourite(context, company_uuid) {
    toggleFavourite(company_uuid)
      .then(() => {
        context.dispatch("getDashboardCompanies")
      })
      .catch(() => {
        HelmNotification(notifications.COMPANY_FAVOURITE_FAIL)
      })
  },
  getDashboardCompanies(context) {
    const loader = Loading.service({ text: "Loading companies...." })
    return getDashboardCompanies()
      .then((response) => {
        context.commit("SET_DASHBOARD_COMPANIES", {
          companies: response.data,
        })
      })
      .catch(() => {
        HelmNotification(notifications.COMPANY_LOAD_FAIL)
      })
      .finally(() => {
        loader.close()
      })
  },
}
