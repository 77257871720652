import session from "./session"
import { Loading } from "element-ui"

export function refreshDataProviderToken(companyUuid, dataSource) {
  return new Promise((resolve, reject) => {
    const loader = Loading.service({ text: `Connecting to ${dataSource}...` })
    getAuthorizationURL(dataSource, companyUuid).then((res) => {
      var win = window.open(res?.data, "authWindow")
      var timer = setInterval(function () {
        if (win.closed) {
          clearInterval(timer)
          console.log("closed")
          reject("ACCESS DENIED")
        }
      }, 1000)
      async function receiveMessage(event) {
        if (event.origin !== window.location.origin) {
          console.warn(`Message received by ${event.origin}; IGNORED.`)
          return
        }
        let message = event.data
        console.log("message", message)

        if (message?.from === "oauth-callback") {
          window.removeEventListener("message", receiveMessage)
          clearInterval(timer)
          if (message?.location) {
            let authLocation = message?.location
            try {
              let result = await authorizeCompany(
                dataSource,
                authLocation,
                companyUuid
              )
              companyUuid = result?.data?.company_id
              loader.close()
              resolve(companyUuid)
            } catch (error) {
              window.removeEventListener("message", receiveMessage)
              loader.close()
              reject(error)
              console.log("error", error)
            }
          } else {
            window.removeEventListener("message", receiveMessage)
            loader.close()
            console.log("error", message?.data?.query?.error)
            reject(message?.data?.query?.error || "ACCESS DENIED")
          }
        }
      }
      window.addEventListener("message", receiveMessage, false)
    })
  })
}

export function conversionConnect(dataSource) {
  return new Promise((resolve, reject) => {
    const loader = Loading.service({ text: `Connecting to ${dataSource}...` })
    getConversionURL(dataSource).then((res) => {
      var win = window.open(res?.data, "authWindow")
      var timer = setInterval(function () {
        if (win?.closed) {
          clearInterval(timer)
          reject("ACCESS DENIED")
        }
      }, 1000)
      async function receiveMessage(event) {
        if (event.origin !== window.location.origin) {
          console.warn(`Message received by ${event.origin}; IGNORED.`)
          return
        }
        let message = event.data
        if (message?.from === "oauth-callback") {
          window.removeEventListener("message", receiveMessage)
          clearInterval(timer)
          if (message?.location) {
            let authLocation = message?.location
            try {
              let result = await getOpenIDInformation(dataSource, authLocation)
              loader.close()
              resolve(result)
            } catch (error) {
              window.removeEventListener("message", receiveMessage)
              loader.close()
              reject(error)
            }
          } else {
            window.removeEventListener("message", receiveMessage)
            console.log("error", message?.data?.query?.error)
            loader.close()
            reject(message?.data?.query?.error || "ACCESS DENIED")
          }
        }
      }
      window.addEventListener("message", receiveMessage, false)
    })
  })
}

export function getAuthorizationURL(dataSource, companyUuid) {
  return session.get(
    `/oauth/${dataSource}/company/login/?company=${
      companyUuid ? companyUuid : ""
    }`
  )
}

export function getConversionURL(dataSource) {
  return session.get(`/oauth/provider-conversion/?provider=${dataSource}`)
}

export function getOpenIDInformation(dataSource, authUrl) {
  return session.post(
    `/oauth/provider-conversion-callback/?provider=${dataSource}`,
    {
      auth_url: authUrl,
    }
  )
}

export function authorizeCompany(dataSource, authUrl, companyUuid) {
  return session.post(`/oauth/${dataSource}/callback/`, {
    auth_url: authUrl,
    company_id: companyUuid,
  })
}
