import { GET_AP, SET_AP, GET_AP_FAILURE } from "@/store/types"

export default {
  [GET_AP](state) {
    state.error = false
  },
  [SET_AP](state, payload) {
    state.error = false
    state.accounts = payload.accounts
  },
  [GET_AP_FAILURE](state) {
    state.error = true
  },
  SET_ACCOUNTPAYABLE(state, payload) {
    const index = state.accounts.findIndex((d) => d.uuid === payload.ap.uuid)
    state.accounts.splice(index, 1, payload.ap)
  },
}
