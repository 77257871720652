import auth from "./modules/auth"
import password from "./modules/password"
import profile from "./modules/profile"
import company from "./modules/company"
import bankaccounts from "./modules/bankaccounts"
import ar from "./modules/ar"
import ap from "./modules/ap"
import users from "./modules/users"
import organization from "./modules/organization"
import permissions from "./modules/permissions"
import subscription from "./modules/subscription"
import orgsettings from "./modules/orgsettings"
import agedBalances from "./modules/agedBalances"
import forecastingRules from "./modules/forecastingRules"
import veem from "./modules/veem"
import dashboard from "./modules/dashboard"

import createLogger from 'vuex/dist/logger';
const debug = process.env.NODE_ENV !== 'production';

export default {
  modules: {
    auth,
    password,
    profile,
    company,
    bankaccounts,
    ar,
    ap,
    users,
    organization,
    permissions,
    subscription,
    orgsettings,
    agedBalances,
    forecastingRules,
    veem,
    dashboard,
  },
  plugins: debug? [ createLogger() ] : [],
}
