export default {
  SET_VEEM_TOKENS(state, { tokens }) {
    state.veemTokens = tokens
  },
  SET_PAYMENT_CONTACTS(state, { paymentContacts }) {
    state.paymentContacts = paymentContacts
  },
  SET_PAYMENT_CONTACT(state, { paymentContact }) {
    const index = state.paymentContacts.findIndex(
      (x) => x.id === paymentContact.id
    )
    if (index === -1) {
      state.paymentContacts.push(paymentContact)
      return
    }
    state.paymentContacts.splice(index, 1, paymentContact)
  },
}
