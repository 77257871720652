import {
  getOrganization,
  updateOrganization,
  createInvitation,
  acceptInvitation,
} from "@/api/organization"
import HelmNotification, { notifications } from "@/lib/notification"

export default {
  GET_ORGANIZATION(context) {
    return getOrganization()
      .then((response) => {
        context.commit("SET_ORGANIZATION", {
          organization: response.data.results[0],
        })
      })
      .catch((error) => {
        HelmNotification(notifications.ORGANIZATION_LOAD_FAIL)
        throw error
      })
  },
  UPDATE_ORGANIZATION(context, { organization }) {
    return updateOrganization(organization)
      .then((response) => {
        context.commit("SET_ORGANIZATION", {
          organization: response.data,
        })
      })
      .catch((error) => {
        HelmNotification(notifications.ORGANIZATION_UPDATE_FAIL)
      })
  },
  createInvitation(context, { email, organization_uuid, permission }) {
    const inviter_uuid = context.rootState.profile.profile.id
    return createInvitation(inviter_uuid, email, organization_uuid, permission)
      .then(() => {
        HelmNotification(notifications.INVITATION_CREATE_SUCCESS)
      })
      .catch((error) => {
        HelmNotification(notifications.INVITATION_CREATE_FAIL)
        throw error
      })
  },
  acceptInvitation(context, { token }) {
    return acceptInvitation(token).catch((error) => {
      HelmNotification(
        notifications.INVITATION_ACCEPT_FAIL,
        error.response.statusText
      )
      throw error
    })
  },
}
