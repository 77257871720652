import axios from "axios"
import session, { baseURL } from "./session"

export function getDashboardCompanies() {
  return session.get(`/dashboard/stats/`)
}

export function getCompanyGraphData(company_uuid) {
  return session.get(`/dashboard/${company_uuid}/graph-data/`)
}

export function toggleFavourite(company_uuid) {
  return session.post(`/company/${company_uuid}/favourite/`)
}

export function getAllCompanyUsers(company_uuid) {
  return session.get(`/dashboard/${company_uuid}/users/`)
}

export function generateAgedBalancesReport(company_uuid, report_form) {
  return session.post(
    `/dashboard/${company_uuid}/reports/aged-balances/`,
    report_form
  )
}

export function sendAgedBalancesReport(company_uuid, report_uuid, email_form) {
  return session.post(
    `/dashboard/${company_uuid}/reports/${report_uuid}/aged-balances/mail/`,
    email_form
  )
}

export function approveAgedBalancesReport(report_uuid, decision) {
  return axios.post(
    `${baseURL}/dashboard/reports/aged-balances/${report_uuid}/approve-report/`,
    decision
  )
}
