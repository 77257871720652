import session from "./session"

export function getOrganization() {
  return session.get("/organization/")
}

export function updateOrganization(organization) {
  return session.put(`/organization/${organization.id}/`, organization)
}

export function createInvitation(
  inviter_uuid,
  email,
  organization_uuid,
  permission
) {
  return session.post("/organizationuserinvitations/", {
    inviter: inviter_uuid,
    email,
    organization_uuid,
    permission,
  })
}

export function acceptInvitation(token) {
  return session.post(`/organizationuserinvitations/${token}/accept_invite/`)
}
