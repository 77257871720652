import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

export const initialState = {
  plans: [],
  subscription: {},
  invoices: [],
}

const state = { ...initialState }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
