<template>
  <div id="app">
    <TrialBar />
    <div class="HelmLoader" :class="{ 'is-loading': loading }">
      <img
        src="/images/brand-helm--loading-animated.svg"
        alt="Helm is Loading..."
        class="HelmLoader__Image"
      />
    </div>
    <Transition name="rootTransition" appear @after-enter="onAfterEnter">
      <RouterView />
    </Transition>
  </div>
</template>

<script>
import TrialBar from "@/components/TrialBar"

export default {
  name: "App",
  components: {
    TrialBar,
  },
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    onAfterEnter() {
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
.HelmLoader {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background: color(brand, background);
  transition: opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);

  &.is-loading {
    opacity: 1;
    pointer-events: all;
  }
}

.HelmLoader__Image {
  opacity: 0.2;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
