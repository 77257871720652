import {
  getScenarioBankAccounts,
  bulkUpdateBankAccounts,
  updateBankAccount,
  createBankAccount,
  deleteBankAccount,
} from "@/api/bank-accounts"

export default {
  async getBankAccounts(context) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario

    const res = await getScenarioBankAccounts(company_uuid, scenario_uuid)
    context.commit("SET_BANKACCOUNTS", { bankAccounts: res.data.results })
  },
  async createBankAccount(context, bankAccount) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario

    const res = await createBankAccount(
      company_uuid,
      scenario_uuid,
      bankAccount
    )

    context.commit("CREATE_BANK_ACCOUNT", { bankAccount: res.data })
  },
  async bulkUpdateBankAccounts(context, bankAccounts) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario

    const res = await bulkUpdateBankAccounts(
      company_uuid,
      scenario_uuid,
      bankAccounts
    )

    context.commit("SET_BULK_UPDATED_BANK_ACCOUNTS", {
      bankAccounts: res.data,
    })
    await context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
      root: true,
    })
  },
  async updateBankAccount(context, bankAccount) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario

    const res = await updateBankAccount(
      company_uuid,
      scenario_uuid,
      bankAccount
    )

    context.commit("SET_UPDATED_BANK_ACCOUNT", {
      bankAccount: res.data,
    })
    await context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
      root: true,
    })
  },
  async deleteBankAccount(context, bankAccount) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario

    const res = await deleteBankAccount(
      company_uuid,
      scenario_uuid,
      bankAccount
    )

    context.commit("DELETE_BANK_ACCOUNT", {
      bankAccount: bankAccount,
    })
    await context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
      root: true,
    })
  },
}
