import { getCompanyAP, updateAP } from "@/api/company"
import HelmNotification, { notifications } from "@/lib/notification"
import { GET_AP, SET_AP, GET_AP_FAILURE } from "@/store/types"

export default {
  getCompanyAP(context) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario
    context.commit(GET_AP)
    return getCompanyAP(company_uuid, scenario_uuid)
      .then(({ data }) => {
        context.commit(SET_AP, { accounts: data.results })
      })
      .catch((error) => {
        context.commit(GET_AP_FAILURE)
        HelmNotification(notifications.AP_LOAD_FAIL)
        throw error
      })
  },
  UPDATE_AP(context, ap) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario
    const oldState = {
      ...context.state.accounts.find((d) => d.uuid === ap.uuid),
    }

    context.commit("SET_ACCOUNTPAYABLE", { ap })
    return updateAP(company_uuid, scenario_uuid, ap)
      .then((response) => {
        context.commit("SET_ACCOUNTPAYABLE", {
          ap: response.data,
        })
        context.dispatch("agedBalances/getAgedBalances", null, {
          root: true,
        })
        context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
          root: true,
        })
      })
      .catch((reason) => {
        HelmNotification(notifications.AP_UPDATE_FAIL)
        context.commit("SET_ACCOUNTPAYABLE", { ap: oldState })
        // re-throw so we can use this to notify users in components
        throw reason
      })
  },
}
