import session from "./session"

export function getForecastingRules(company_uuid, scenario_uuid) {
  return session.get(
    `/company/${company_uuid}/scenario/${scenario_uuid}/forecasting-rules/`
  )
}

export function getForecastingRulesGroupedByClass(company_uuid, scenario_uuid) {
  return session.get(
    `/company/${company_uuid}/scenario/${scenario_uuid}/forecasting-rules/get_by_class/`
  )
}

export function getForecastingRulesBalanceSheetItems(company_uuid, scenario_uuid) {
  return session.get(
    `/company/${company_uuid}/scenario/${scenario_uuid}/forecasting-rules/get_balance_sheet_items/`
  )
}

export function updateForecastingRule(
  company_uuid,
  scenario_uuid,
  forecasting_rule
) {
  return session.put(
    `/company/${company_uuid}/scenario/${scenario_uuid}/forecasting-rules/${forecasting_rule.id}/`,
    forecasting_rule
  )
}

export function createForecastingRule(
  company_uuid,
  scenario_uuid,
  forecasting_rule
) {
  return session.post(
    `/company/${company_uuid}/scenario/${scenario_uuid}/forecasting-rules/`,
    forecasting_rule
  )
}

export function deleteForecastingRule(
  company_uuid,
  scenario_uuid,
  forecasting_rule
) {
  return session.delete(
    `/company/${company_uuid}/scenario/${scenario_uuid}/forecasting-rules/${forecasting_rule.id}/`
  )
}

export function bulkDeleteForecastingRules(
  company_uuid,
  scenario_uuid,
  aged_balances
) {
  const aged_balances_ids = aged_balances.map((ab) => ab.id)
  return session.post(
    `/company/${company_uuid}/scenario/${scenario_uuid}/forecasting-rules/multiple_delete/`,
    { ids: aged_balances_ids }
  )
}

export function bulkUpdateForecastingRules(company_uuid, scenario_uuid, data) {
  return session.put(
    `/company/${company_uuid}/scenario/${scenario_uuid}/forecasting-rules/multiple_update/`,
    data
  )
}
