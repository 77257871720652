import session from "./session"

export function getCompanyList() {
  return session.get("/company/")
}

export function companyCreate(company) {
  return session.post("/company/", company)
}

export function getCompanyCreateOptions() {
  return session.options("/company/")
}

export function deleteCompany(id) {
  return session.delete("/company/" + id + "/")
}

export function getCompanyDetail(id) {
  return session.get("/company/" + id + "/")
}

export function updateCompany(company) {
  return session.put(`/company/${company.uuid}/`, company)
}

export function getCompanyScenarios(uuid) {
  return session.get(`/company/${uuid}/scenario/`)
}

export function updateCompanyScenario(company_uuid, scenario) {
  return session.put(
    `/company/${company_uuid}/scenario/${scenario.uuid}/`,
    scenario
  )
}

export function createCompanyScenario(company_uuid, scenario) {
  return session.post(`/company/${company_uuid}/scenario/`, scenario)
}

export function changeDefaultScenario(company_uuid, scenario_uuid) {
  return session.post(`/company/${company_uuid}/scenario/change_default/`, {
    new_default: scenario_uuid,
  })
}

export function deleteCompanyScenario(company_uuid, scenario_uuid) {
  return session.delete(`/company/${company_uuid}/scenario/${scenario_uuid}/`)
}

export function getTransactions(
  company_uuid,
  scenario_uuid,
  start_date,
  end_date
) {
  return session.get(
    `/company/${company_uuid}/scenario/${scenario_uuid}/transactions/`,
    {
      params: {
        start_date,
        end_date,
      },
    }
  )
}

export function getCashflowTransactions(
  company_uuid,
  scenario_uuid,
  group,
  start_date,
  end_date
) {
  return session.get(
    `/company/${company_uuid}/scenario/${scenario_uuid}/cashflow_transactions/`,
    {
      params: {
        group,
        start_date,
        end_date,
      },
    }
  )
}

export function getDailytotals(
  company_uuid,
  scenario_uuid,
  start_date,
  end_date
) {
  return session.get(
    `/company/${company_uuid}/scenario/${scenario_uuid}/dailytotals/`,
    {
      params: {
        start_date,
        end_date,
      },
    }
  )
}

export function getCompanyAR(company_uuid, scenario_uuid) {
  return session.get(`/company/${company_uuid}/scenario/${scenario_uuid}/ar/`)
}

export function updateAR(company_uuid, scenario_uuid, ar) {
  return session.put(
    `/company/${company_uuid}/scenario/${scenario_uuid}/ar/${ar.uuid}/`,
    ar
  )
}

export function getCompanyAP(company_uuid, scenario_uuid) {
  return session.get(`/company/${company_uuid}/scenario/${scenario_uuid}/ap/`)
}

export function updateAP(company_uuid, scenario_uuid, ap) {
  return session.put(
    `/company/${company_uuid}/scenario/${scenario_uuid}/ap/${ap.uuid}/`,
    ap
  )
}

export function fetchData(company_uuid, timeframe = 12, backup_data) {
  return session.post(`/company/${company_uuid}/fetch_data/`, {
    import_timeframe: timeframe,
    ...backup_data
  })
}

export function fetchDataPercentage(task_id) {
  return session.get(`/tasks/${task_id}`)
}

export function fetchSageConnections(company_uuid) {
  return session.get(
    `oauth/sage/company/get_available_connections/?company=${company_uuid}`
  )
}

export function pickSageTenantID(company_uuid, tenant_id) {
  return session.post(
    `oauth/sage/company/pick_sage_tenant_id/?company=${company_uuid}`,
    {
      tenant_id,
    }
  )
}

export function updateData(company_uuid) {
  return session.post(`/company/${company_uuid}/update_data/`)
}

export function runAverageDaysETL(company_uuid, type = null) {
  let url = `/company/${company_uuid}/run_average_days_etl/`
  if (type) {
    url += `?mode=${type}`
  }
  return session.post(url)
}

export function runForecastingRuleETL(company_uuid) {
  return session.post(`/company/${company_uuid}/run_forecasting_rule_etl/`)
}

export function dropToken(company_uuid) {
  return session.post(
    `/oauth/quickbooks/company/revoke/?company=${company_uuid}`
  )
}

export function revokeXeroOAuth(company_uuid) {
  return session.post(`/oauth/xero/company/revoke/?company=${company_uuid}`)
}

export function revokeSageOAuth(company_uuid) {
  return session.post(`/oauth/sage/company/revoke/?company=${company_uuid}`)
}

export function getChartOfAccounts(company_uuid) {
  return session.get(`/company/${company_uuid}/contacts/`)
}

export function createContact(company_uuid, company_name) {
  return session.post(`/company/${company_uuid}/contacts/`, {
    company_name,
    is_supplier: false,
    is_customer: false,
  })
}

export function createAccount(company_uuid, account_name) {
  return session.post(`company/${company_uuid}/otheraccounts/`, {
    account_name: account_name,
    account_type: "USERGENERATED",
  })
}

export function getTransactionCategories(company_uuid) {
  return session.get(`/company/${company_uuid}/otheraccounts/`)
}

export function getBankAccountTypes(company_uuid) {
  return session.get(
    `/company/${company_uuid}/otheraccounts/bank_account_types/`
  )
}

export function createInvitation(
  inviter_uuid,
  email,
  company_uuid,
  permission
) {
  return session.post("/companyuserinvitations/", {
    inviter: inviter_uuid,
    email,
    company_uuid,
    permission,
  })
}

export function acceptInvitation(token) {
  return session.post(`/companyuserinvitations/${token}/accept_invite/`)
}

export function getCompanyUsers(company_uuid) {
  return session.get(`/company/${company_uuid}/company_users/`)
}

export function createCompanyUser(company_user) {
  return session.post(`/companyuser/`, company_user)
}

export function updateCompanyUser(company_user) {
  return session.put(`/companyuser/${company_user.uuid}/`, company_user)
}

export function deleteCompanyUser(company_user_uuid) {
  return session.delete(`/companyuser/${company_user_uuid}/`)
}

export function updateViewBy(company_uuid, type, viewBy) {
  const data = {
    type: type,
    viewBy: viewBy,
  }
  return session.put(`/company/${company_uuid}/update_view_by/`, data)
}

export function updateForecastBy(company_uuid, forecastBy, type) {
  const data = {
    forecastBy: forecastBy,
    type: type,
  }
  return session.put(`/company/${company_uuid}/update_forecast_by/`, data)
}

export function getUserCompaniesLegacy(user_uuid) {
  return session.get(`/users/${user_uuid}/companies/`)
}

export function getUserCompanies(user_uuid) {
  return session.get(`/users/${user_uuid}/get_companies/`)
}

export function getStats(company_uuid) {
  return session.get(`/company/${company_uuid}/get_stats/`)
}

export function validateOAuthCredentials(company_uuid) {
  return session.get(`/company/${company_uuid}/validate_oauth_credentials/`)
}

export function set_payment_approver(company_uuid, user_uuid) {
  return session.post(`/company/${company_uuid}/set_payment_approver/`, {
    company_user: user_uuid,
  })
}

export function getCompanyPriceChoices() {
  return session.get("/company/get_price_choices/")
}
