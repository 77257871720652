import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

const state = {
  loading: true,
  error: false,
  error_message: "",
  userCompanies: [],
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
