import session from "./session"

export function revokeVeemToken(company_uuid) {
  return session.post(`oauth/veem/company/${company_uuid}/revoke/`)
}

export function getVeemTokens(company_uuid) {
  return session.get(`oauth/veem/${company_uuid}/`)
}

export function getPaymentContacts(company_uuid) {
  return session.get(`payment-contacts/company/${company_uuid}/`)
}

export function createPaymentContact(company_uuid, contact) {
  return session.post(`payment-contacts/company/${company_uuid}/`, contact)
}

export function updatePaymentContact(company_uuid, contact) {
  return session.put(
    `payment-contacts/company/${company_uuid}/${contact.id}/`,
    contact
  )
}

export function createPayment(company_uuid, payment) {
  return session.post(`payments/company/${company_uuid}/`, payment)
}

export function deletePayment(company_uuid, payment_id) {
  return session.delete(`payments/company/${company_uuid}/${payment_id}/`)
}

export function deletePayments(company_uuid, payment_ids) {
  return session.post(`payments/company/${company_uuid}/multiple_delete/`, {
    ids: payment_ids,
  })
}

export function approvePayments(company_uuid, payment_ids) {
  const comma_separated_ids = payment_ids.join(",")
  return session.post(
    `payments/company/${company_uuid}/approve_payments/?ids=${comma_separated_ids}`
  )
}
