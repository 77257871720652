/**
 * Notification module.
 * @module lib/notification
 * @see module:lib/notification
 */
import { Notification } from "element-ui"

export const ERROR = "error"
export const SUCCESS = "success"
export const WARNING = "warning"
export const INFO = "info"

/** Define notification presets here */
export const notifications = {
  /** Company module messages */
  DATA_CONNECTION_SUCCESS: {
    title: "Your account has successfully been linked to Helm.",
    type: SUCCESS,
  },
  DATA_CONNECTION_FAIL: {
    title: "Your account failed to link to Helm, please try again",
    type: ERROR,
  },
  REFRESH_TOKEN_EXPIRED: {
    title:
      "Your account failed to link to Helm. You will be redirected to re-authorize. Please retry the action after connecting.",
    type: ERROR,
  },
  COMPANY_LOAD_FAIL: {
    title: "Error loading company data",
    type: ERROR,
  },
  COMPANY_UPDATE_FAIL: {
    title: "Error updating company",
    type: ERROR,
  },
  COMPANY_DELETE_FAIL: {
    title: "Error deleting company",
    type: ERROR,
  },
  XERO_SYNC_FAIL: {
    title:
      "Error encountered while syncing expected dates to Xero. Please try again or contact support.",
    type: ERROR,
  },
  SCENARIO_LOAD_FAIL: {
    title: "Error loading scenario data",
    type: ERROR,
  },
  SCENARIO_DUPLICATE_FAIL: {
    title: "This scenario was not duplicated, please try again",
    type: ERROR,
  },
  SCENARIO_UPDATE_FAIL: {
    title: "This scenario was not updated, please try again",
    type: ERROR,
  },
  SCENARIO_CREATE_FAIL: {
    title: "A new scenario was not created, please try again",
    type: ERROR,
  },
  SCENARIO_DELETE_FAIL: {
    title: "This scenario could not be deleted, please try again",
    type: ERROR,
  },
  ALTERATION_UPDATE_FAIL: {
    title: "Error updating line item.",
    type: ERROR,
  },
  COMPANY_CREATE_FAIL: {
    title: "Error creating company",
    type: ERROR,
  },
  DROP_ACCOUNTING_CONNECTION_FAIL: {
    title: "Unable to unlink your data provider",
    type: ERROR,
  },
  INVITATION_CREATE_SUCCESS: {
    title: "Successfully invited user",
    type: SUCCESS,
  },
  INVITATION_CREATE_FAIL: {
    title: "Unable to invite user",
    type: ERROR,
  },
  INVITATION_CREATE_FAIL_USER_ALREADY_EXISTS: {
    title: "Invited email already exists",
    type: ERROR,
  },
  INVITATION_ACCEPT_FAIL: {
    title: "Invitation failed",
    type: ERROR,
  },
  COMPANY_USERS_LOAD_FAIL: {
    title: "Error loading company users",
    type: ERROR,
  },
  COMPANY_USERS_UPDATE_FAIL: {
    title: "Error updating company user",
    type: ERROR,
  },
  COMPANY_USERS_CREATE_FAIL: {
    title: "Error adding user to company",
    type: ERROR,
  },
  COMPANY_USERS_DELETE_FAIL: {
    title: "Error removing user from company",
    type: ERROR,
  },
  COMPANY_FAVOURITE_FAIL: {
    title: "Error favouriting company",
    type: ERROR,
  },
  /** Chart module messages */
  CHART_DRAG_PAST_FAIL: {
    title: "Cannot move line item into the past",
    type: WARNING,
  },
  CHART_DRAG_PAYMENT_FAIL: {
    title: "Cannot move Invoice with scheduled Payment",
    type: WARNING,
  },
  /** Organization messages */
  ORGANIZATION_LOAD_FAIL: {
    title: "Error loading organization details.",
    type: ERROR,
  },
  ORGANIZATION_UPDATE_FAIL: {
    title: "Error updating organization",
    type: ERROR,
  },
  /** AR messages */
  AR_LOAD_FAIL: {
    title: "Error loading company AR",
    type: ERROR,
  },
  AR_UPDATE_FAIL: {
    title: "Error updating company AR",
    type: ERROR,
  },
  INVOICE_UPDATE_FAIL: {
    title: "Error updating forecasted invoice.",
    type: ERROR,
  },
  /** AP messages */
  AP_LOAD_FAIL: {
    title: "Error loading company AP",
    type: ERROR,
  },
  AP_UPDATE_FAIL: {
    title: "Error updating company AP",
    type: ERROR,
  },
  AGED_BALANCES_UPDATE_SUCCESS: {
    title: "Successfully updated the payment date.",
    type: SUCCESS,
  },
  /** Forecasting messages */
  RULE_LOAD_FAIL: {
    title: "Error loading forecasting rules",
    type: ERROR,
  },
  RULE_CREATE_FAIL: {
    title: "Unable to create forecasting rule",
    type: ERROR,
  },
  RULE_UPDATE_FAIL: {
    title: "Unable to update forecasting rule",
    type: ERROR,
  },
  RULE_DELETE_FAIL: {
    title: "Unable to delete forecasting rule",
    type: ERROR,
  },
  /** Cash Account messages */
  BANK_ACCOUNT_UPDATE_FAIL: {
    title: "Unable to update bank account",
    type: ERROR,
  },
  BANK_ACCOUNT_DELETE_FAIL: {
    title: "Unable to delete bank account",
    type: ERROR,
  },
  BANK_ACCOUNT_CREATE_FAIL: {
    title: "Unable to create bank account",
    type: ERROR,
  },
  BANK_ACCOUNT_EXISTS: {
    title: "Bank Account already exists",
    type: ERROR,
  },
  /** User messages */
  USER_LOAD_FAIL: {
    title: "Error loading users",
    type: ERROR,
  },
  USER_UPDATE_FAIL: {
    title: "Error updating user profile",
    type: ERROR,
  },
  /** Subscription messages */
  PLAN_LOAD_FAIL: {
    title: "Error loading payment plans",
    type: ERROR,
  },
  SUBSCRIPTION_STATUS_LOAD_FAIL: {
    title: "Error getting subscription status",
    type: ERROR,
  },
  TRIAL_CREATE_FAIL: {
    title: "Error activating trial",
    type: ERROR,
  },
  SUBSCRIPTION_CANCEL_SUCCESS: {
    title:
      "Your subscription has been cancelled, your plan will end at the end of your current billing period",
    type: SUCCESS,
  },
  SUBSCRIPTION_CANCEL_FAIL: {
    title: "Error cancelling subscription",
    type: ERROR,
  },
  INVOICE_LOAD_FAIL: {
    title: "Error loading invoices",
    type: ERROR,
  },
  /** Profile messages */
  PROFILE_LOAD_FAIL: {
    title: "Error loading your profile",
    type: ERROR,
  },
  /** Forgot password */
  CHANGE_PASSWORD_SUCCESS: {
    title: "Your password has been updated",
    type: SUCCESS,
  },
  CHANGE_PASSWORD_FAIL: {
    title: "We're unable to update your password",
    type: ERROR,
  },
  FORGOT_PASSWORD_SENT_SUCCESS: {
    title:
      "Check your email, including the spam folders, in a few moments for further instructions",
    type: SUCCESS,
  },
  FORGOT_PASSWORD_SENT_FAIL: {
    title: "We're unable to process your request",
    type: ERROR,
  },
  CASHFLOW_SUMMARY_FAIL: {
    title: "Unable to request cashflow summary data",
    type: ERROR,
  },
  /** company options **/
  COMPANY_TIMEZONE_OPTIONS_FAIL: {
    title:
      "Unable to request timezone options, please refresh the page and try again",
    type: ERROR,
  },
  /** Veem messages */
  VEEM_CONNECT_FAIL: {
    title: "We're unable to connect your account to Veem",
    type: ERROR,
  },
  VEEM_CONNECT_SUCCESS: {
    title: "Successfully connected to Veem",
    type: SUCCESS,
  },
  VEEM_REVOKE_SUCCESS: {
    title: "Successfully revoked Veem connection",
    type: SUCCESS,
  },
  VEEM_REVOKE_FAIL: {
    title: "We're unable to revoke Veem connection",
    type: ERROR,
  },
  VEEM_GET_TOKENS_FAIL: {
    title: "We're unable to fetch Veem connection status",
    type: ERROR,
  },
  //  PDF messages
  PDF_GENERATE_FAIL: {
    title:
      "An error has occured while generating the PDF. Please try again or contact support",
    type: ERROR,
  },
  // View By ADR/ADP
  VIEW_BY_UPDATE_FAIL: {
    title:
      "Unable to update View By preference. Please try again or contact support",
    type: ERROR,
  },
  REPORT_SEND_SUCCESS: {
    title:
      "Report is being prepared for email. Your recipients will receive their emails shortly.",
    type: SUCCESS,
  },
  REPORT_CREATE_FAIL: {
    title: "Unable to create report. Please try again or contact support",
    type: ERROR,
  },
  MISSING_FORM_DATA: {
    title: "Name / color can not be empty",
    type: ERROR,
  },
  CREDIT_CARD_SETUP_SUCCESS: {
    title:
      "Great news! Your credit card setup is complete. Get ready to navigate and take control with Helm!",
    type: SUCCESS,
  },
}

/**
 * Displays a notification. Wrapper for ElementUI function.
 *
 * @param {object} notification_object - Reference to notification from giant
 * notifications object.
 * @param {string} message - Optional message to display.
 *
 * @example
 * HelmNotification(notifications.INVITATION_ACCEPT_FAIL, error.response.statusText)
 */
export default function displayNotification({ title, type }, message) {
  /**
   * ElementUI Notification function
   *
   * @param {string} type - Adds icon and coloring, valid options are success, warning, info, error
   * @see {@link https://element.eleme.io/#/en-US/component/notification}
   */
  const validNotificationTypes = [SUCCESS, WARNING, INFO, ERROR]

  if (!validNotificationTypes.includes(type)) {
    console.warn(
      "Invalid notification type specified. Please use valid type: success, warning, info, error"
    )
  }

  Notification({
    title,
    type,
    message,
    offset: 100,
    duration: 3000,
  })
}
