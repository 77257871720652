import {
  PASSWORD_RESET_BEGIN,
  PASSWORD_RESET_CLEAR,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_SUCCESS,
} from "../../types"

export default {
  [PASSWORD_RESET_BEGIN](state) {
    state.resetLoading = true
  },
  [PASSWORD_RESET_CLEAR](state) {
    state.resetCompleted = false
    state.resetError = false
    state.resetLoading = false
  },
  [PASSWORD_RESET_FAILURE](state) {
    state.resetError = true
    state.resetLoading = false
  },
  [PASSWORD_RESET_SUCCESS](state) {
    state.resetCompleted = true
    state.resetError = false
    state.resetLoading = false
  },
}
