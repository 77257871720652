import Vue from "vue"
import moment from "moment"
import {
  GET_COMPANIES,
  SET_COMPANIES,
  GET_COMPANIES_FAILURE,
  GET_COMPANY_DETAIL,
  SET_COMPANY_DETAIL,
  GET_COMPANY_DETAIL_FAILURE,
  PATCH_COMPANY_DETAIL,
  PATCH_COMPANY_SUCCESS,
  PATCH_COMPANY_FAILURE,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,
} from "../../types"

export default {
  openCompanyDrawer(state) {
    state.companyDrawerOpen = true
  },
  closeCompanyDrawer(state) {
    state.companyDrawerOpen = false
  },
  [GET_COMPANIES](state) {
    state.error = false
  },
  [SET_COMPANIES](state, { results }) {
    state.companies = results
  },
  [GET_COMPANIES_FAILURE](state) {
    state.error = true
  },
  [GET_COMPANY_DETAIL](state) {
    state.error = false
  },
  [SET_COMPANY_DETAIL](state, data) {
    state.error = false
    state.company_detail = data
    state.cashflowDrawerOpen = false
  },
  [GET_COMPANY_DETAIL_FAILURE](state) {
    state.error = true
  },
  [PATCH_COMPANY_DETAIL](state) {
    state.error = false
  },
  [PATCH_COMPANY_SUCCESS](state, { company_detail }) {
    state.error = false
    state.company_detail = company_detail
  },
  [PATCH_COMPANY_FAILURE](state) {
    state.error = true
  },

  [DELETE_COMPANY](state) {
    state.error = false
  },
  [DELETE_COMPANY_SUCCESS](state, company_uuid) {
    state.error = false
    const companyIndex = state.companies.findIndex(
      (d) => d.uuid === company_uuid
    )
    const userCompanyIndex = state.user_companies.findIndex(
      (d) => d.company.uuid === company_uuid
    )
    state.companies.splice(companyIndex, 1)
    state.user_companies.splice(userCompanyIndex, 1)
  },
  [DELETE_COMPANY_FAILURE](state) {
    state.error = true
  },

  openCashflowDrawer(state, val) {
    state.activeSideMenuTab = val
    state.cashflowDrawerOpen = true
  },
  closeCashflowDrawer(state) {
    state.activeSideMenuTab = "none"
    state.cashflowDrawerOpen = false
  },
  updateActiveScenario(state, key) {
    state.activeScenario = key
  },
  RESET_ACTIVE_SCENARIO(state) {
    state.activeScenario = null
  },
  RESET_IMPORT_DATE(state) {
    state.company_detail = {}
  },
  SET_CASHFLOW_TABLE(state, { cashflowTableOpen }) {
    state.cashflowTableOpen = cashflowTableOpen
  },
  SET_SCENARIOS(state, { scenarios }) {
    state.scenarios = scenarios
  },
  RESET_SCENARIOS(state) {
    state.scenarios = []
  },
  SET_SCENARIO_TRANSACTIONS(state, { scenario_uuid, transactions }) {
    Vue.set(
      state.scenarios.find((scenario) => scenario.uuid === scenario_uuid),
      "transactions",
      [...transactions]
    )
  },
  SET_SCENARIO_DAILYTOTALS(state, { scenario_uuid, dailytotals }) {
    Vue.set(
      state.scenarios.find((scenario) => scenario.uuid === scenario_uuid),
      "dailytotals",
      [...dailytotals]
    )
  },
  SET_SCENARIO(state, { scenario }) {
    const scenarioClone = { ...scenario }
    state.scenarios = state.scenarios.map((obj) => {
      if (obj.uuid === scenarioClone.uuid) {
        // merge previous and new scenario
        // new scenario overwrites previous
        return { ...obj, ...scenarioClone }
      }
      return obj
    })
  },
  REMOVE_SCENARIO(state, { scenario_uuid }) {
    if (state.activeScenario == scenario_uuid) {
      // set active scenario to base scenario
      const baseScenario = state.scenarios.find((obj) => obj.base_case)
      state.activeScenario = baseScenario.uuid
    }

    state.scenarios = state.scenarios.filter(
      (obj) => obj.uuid !== scenario_uuid
    )
  },
  INSERT_SCENARIO(state, { scenario }) {
    state.scenarios.push(scenario)
  },
  SET_DATES(state, { dates }) {
    state.dates = dates
  },
  SET_COMPANY(state, { company }) {
    state.company_detail = company
  },
  SET_CHART_OF_ACCOUNTS(state, { chartOfAccounts }) {
    state.chartOfAccounts = chartOfAccounts
  },
  SET_CATEGORIES(state, { categories }) {
    state.categories = categories
  },
  SET_USERS(state, { users }) {
    state.users = users
  },
  SET_USER_COMPANIES(state, { companies }) {
    state.user_companies = companies
  },
  SET_LAST_IMPORT_DATE(state, { date }) {
    // format the date to match how the backend API returns datetime strings
    const dateString = moment.utc(date).format("YYYY-MM-DDTHH:mm:ss+0000")
    state.company_detail.last_import_date = dateString
  },
  SET_LOADING(state, val) {
    state.loading = val
  },
  SET_VIEW_BY(state, { type, viewBy }) {
    if (type === "ar") state.company_detail.view_by_ar = viewBy
    else state.company_detail.view_by_ap = viewBy
  },
  SET_FORECAST_BY(state, { forecastBy, type }) {
    if (type === "ar") state.company_detail.forecast_by_ar = forecastBy
    else state.company_detail.forecast_by_ap = forecastBy
  },
}
