import Vue from "vue"
import * as Sentry from "@sentry/browser"
import { Loading } from "element-ui"

import {
  getCompanyList,
  getCompanyDetail,
  updateCompany,
  deleteCompany,
  fetchData,
  updateData,
  getCompanyScenarios,
  getTransactions,
  getDailytotals,
  updateCompanyScenario,
  createCompanyScenario,
  deleteCompanyScenario,
  companyCreate,
  dropToken,
  revokeXeroOAuth,
  getChartOfAccounts,
  createContact,
  createAccount,
  getTransactionCategories,
  createInvitation,
  acceptInvitation,
  getCompanyUsers,
  updateCompanyUser,
  createCompanyUser,
  deleteCompanyUser,
  getUserCompaniesLegacy,
  validateOAuthCredentials,
  set_payment_approver,
  runAverageDaysETL,
  runForecastingRuleETL,
  updateViewBy,
  updateForecastBy,
  revokeSageOAuth,
  fetchSageConnections,
  pickSageTenantID,
  changeDefaultScenario,
} from "@/api/company"
import { refreshDataProviderToken } from "@/api/provider"

import HelmNotification, { notifications } from "@/lib/notification"
import {
  GET_COMPANIES,
  SET_COMPANIES,
  GET_COMPANIES_FAILURE,
  GET_COMPANY_DETAIL,
  SET_COMPANY_DETAIL,
  GET_COMPANY_DETAIL_FAILURE,
  PATCH_COMPANY_DETAIL,
  PATCH_COMPANY_SUCCESS,
  PATCH_COMPANY_FAILURE,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,
} from "../../types"
import { DataSources } from "@/lib/constants"
import { parseDateToUnix } from "@/lib/utils"
import { longPollPercentage } from "@/lib/celery-task"

const IMPORT_MESSAGE_OPTIONS = {
  lock: true,
  text: "We are currently importing your data, this will take a few minutes.",
}
const OAUTH_MESSAGE_OPTIONS = {
  lock: true,
  text: "We are currently connecting to your accounting system, please wait a few moments...",
}

export default {
  getCompanies({ commit }) {
    commit(GET_COMPANIES)
    return getCompanyList()
      .then(({ data }) => commit(SET_COMPANIES, data))
      .catch((error) => {
        HelmNotification(notifications.COMPANY_LOAD_FAIL)
        commit(GET_COMPANIES_FAILURE)
        throw error
      })
  },
  async getCompanyDetail({ commit }, company_id) {
    commit(GET_COMPANY_DETAIL)
    try {
      const res = await getCompanyDetail(company_id)
      commit(SET_COMPANY_DETAIL, res.data)
      return res
    } catch (error) {
      HelmNotification(notifications.COMPANY_LOAD_FAIL)
      commit(GET_COMPANY_DETAIL_FAILURE)
      throw error
    }
  },
  resetScenarios(context) {
    return new Promise((resolve) => {
      context.commit("RESET_ACTIVE_SCENARIO")
      context.commit("RESET_SCENARIOS")
      resolve()
    })
  },
  updateCompany(context, company_obj) {
    context.commit(PATCH_COMPANY_DETAIL)
    return updateCompany(company_obj)
      .then(({ data }) => {
        context.commit(PATCH_COMPANY_SUCCESS, { company_detail: data })
        context.dispatch("getUserCompaniesLegacy").catch(() => {
          // notification already displayed in source function
        })
      })
      .catch((error) => {
        HelmNotification(notifications.COMPANY_UPDATE_FAIL)
        context.commit(PATCH_COMPANY_FAILURE)
        throw error
      })
  },
  updateCompanyField(context, { key, value }) {
    const company = { ...context.state.company_detail }
    company[key] = value
    return updateCompany(company).then(({ data }) => {
      context.commit(PATCH_COMPANY_SUCCESS, { company_detail: data })
    })
  },
  async deleteCompany(context, company_uuid) {
    context.commit(DELETE_COMPANY)
    const loader = Loading.service("Deleting...")
    return new Promise((resolve, reject) => {
      deleteCompany(company_uuid)
        .then(() => {
          context.commit(DELETE_COMPANY_SUCCESS, company_uuid)
          resolve()
        })
        .catch((error) => {
          context.commit(DELETE_COMPANY_FAILURE)
          reject(error)
        })
    }).finally(() => {
      loader.close()
    })
  },
  async IMPORT_DATA(context, { dataSource }) {
    let response = null
    try {
      let companyUuid = await refreshDataProviderToken(null, dataSource)
      if (dataSource == DataSources.SAGE) {
        response = await fetchSageConnections(companyUuid)
      }
      HelmNotification(notifications.DATA_CONNECTION_SUCCESS)
      return {
        company: companyUuid,
        response: response,
      }
    } catch (error) {
      console.log(error)
      HelmNotification(notifications.DATA_CONNECTION_FAIL)
    }
  },
  async PICK_SAGE_TENANT_ID(context, { company_uuid, tenantId }) {
    const loader = Loading.service(OAUTH_MESSAGE_OPTIONS)
    let response = await pickSageTenantID(company_uuid, tenantId)
    loader.close()
    return response
  },
  async FETCH_DATA(context, { company_uuid }) {
    const loader = Loading.service(IMPORT_MESSAGE_OPTIONS)
    await fetchData(company_uuid)
    loader.close()
    HelmNotification(notifications.DATA_CONNECTION_SUCCESS)
  },
  async UPDATE_DATA(context, { companyUuid, responseCallback }) {
    try {
      let response = await updateData(companyUuid)
      let taskId = response?.data?.task_id
      await longPollPercentage(taskId, responseCallback)
      context.dispatch("getCompanyDetail", companyUuid)
      HelmNotification(notifications.DATA_CONNECTION_SUCCESS)
    } catch (error) {
      HelmNotification(notifications.DATA_CONNECTION_FAIL)
      Sentry.captureException(error)
      throw error
    }
  },
  async runAverageDaysETL(context, { companyUuid, type }) {
    try {
      let response = await runAverageDaysETL(companyUuid, type)
      let taskId = response?.data?.task_id
      await longPollPercentage(taskId)
    } catch (error) {
      throw error
    }
    // ar/ap store modules will refresh Contact objects
    await context.dispatch("ar/getCompanyAR", null, { root: true })
    await context.dispatch("ap/getCompanyAP", null, { root: true })
    await context.dispatch("agedBalances/getAgedBalances", null, {
      root: true,
    })
    await context.dispatch("FETCH_SCENARIOS", companyUuid)
    // optimistically update state
    const company = { ...context.state.company_detail }
    if (type === "adr") {
      company["ran_average_days_adr_etl"] = true
    } else if (type === "adp") {
      company["ran_average_days_adp_etl"] = true
    }
    context.commit(PATCH_COMPANY_SUCCESS, { company_detail: company })
  },
  async runForecastingRuleETL(context, companyUuid) {
    try {
      let response = await runForecastingRuleETL(companyUuid)
      let taskId = response?.data?.task_id
      await longPollPercentage(taskId)
    } catch (error) {
      throw error
    }
    await context.dispatch("forecastingRules/getForecastingRules", null, {
      root: true,
    })
    await context.dispatch("FETCH_SCENARIOS", companyUuid)
    // optimistically update state
    const company = { ...context.state.company_detail }
    company["ran_forecasting_rule_etl"] = true
    context.commit(PATCH_COMPANY_SUCCESS, { company_detail: company })
  },
  async getScenarios(context, company_uuid) {
    /**
     * Barebones function without the additional requests made in
     * FETCH_SCENARIOS.
     */
    const response = await getCompanyScenarios(company_uuid)
    const scenarios = response.data.results
    return scenarios
  },
  UPDATE_ACTIVE_SCENARIO(context, scenario_uuid) {
    context.commit("SET_LOADING", true)
    let company_uuid = context.state.company_detail.uuid
    const [start_date, end_date] = context.state.dates
    getTransactions(company_uuid, scenario_uuid, start_date, end_date).then(
      (res) => {
        let transactions = res.data

        context.commit("SET_SCENARIO_TRANSACTIONS", {
          scenario_uuid: scenario_uuid,
          transactions,
        })
      }
    )
    context.commit("updateActiveScenario", scenario_uuid)
    context.commit("SET_LOADING", false)
  },
  FETCH_SCENARIOS(context, company_uuid) {
    context.commit("SET_LOADING", true)
    const [start_date, end_date] = context.state.dates
    const today = parseDateToUnix(context.getters.todayInCompanyTimezone)

    return new Promise((resolve, reject) => {
      getCompanyScenarios(company_uuid)
        .then((response) => {
          const scenarios = response.data.results
          context.commit("SET_SCENARIOS", { scenarios })
          let activeScenarioUuid = context.state.activeScenario
          if (!activeScenarioUuid) {
            activeScenarioUuid = scenarios.find(
              (item) => item.base_case == true
            ).uuid
          }
          scenarios.forEach((scenario) => {
            if (scenario.visible == true) {
              getDailytotals(company_uuid, scenario.uuid, start_date, end_date)
                .then((res) => {
                  let dailytotals = res.data

                  // if a past transaction is not part of the base case
                  // than discard it
                  if (!scenario.base_case) {
                    dailytotals = dailytotals.filter(
                      (obj) => parseDateToUnix(obj.row) >= today
                    )
                  }
                  context.commit("SET_SCENARIO_DAILYTOTALS", {
                    scenario_uuid: scenario.uuid,
                    dailytotals,
                  })
                })
                .catch((error) => {
                  HelmNotification(notifications.SCENARIO_LOAD_FAIL)
                  context.commit("SET_LOADING", false)
                  reject(error)
                })
            } else {
              context.commit("SET_SCENARIO_DAILYTOTALS", {
                scenario_uuid: scenario.uuid,
                dailytotals: [],
              })
            }
            if (activeScenarioUuid == scenario.uuid) {
              getTransactions(
                company_uuid,
                activeScenarioUuid,
                start_date,
                end_date
              )
                .then((res) => {
                  let transactions = res.data
                  context.commit("SET_SCENARIO_TRANSACTIONS", {
                    scenario_uuid: activeScenarioUuid,
                    transactions,
                  })
                })
                .catch((error) => {
                  HelmNotification(notifications.SCENARIO_LOAD_FAIL)
                  context.commit("SET_LOADING", false)
                  reject(error)
                })
            }
          })
          context.commit("updateActiveScenario", activeScenarioUuid)
          context.commit("SET_LOADING", false)
          Vue.nextTick(resolve())
        })
        .catch((error) => {
          HelmNotification(notifications.SCENARIO_LOAD_FAIL)
          context.commit("SET_LOADING", false)
          reject(error)
        })
    })
  },
  UPDATE_SCENARIO(context, scenario) {
    const company_uuid = context.state.company_detail.uuid
    const oldState = {
      ...context.state.scenarios.find((obj) => obj.uuid === scenario.uuid),
    }
    delete oldState.transactions
    delete oldState.dailytotals

    if (!scenario.duplicate) context.commit("SET_SCENARIO", { scenario })
    return updateCompanyScenario(company_uuid, scenario)
      .then(() => {
        context.dispatch("FETCH_SCENARIOS", company_uuid)
      })
      .catch((e) => {
        if (!scenario.duplicate) {
          context.commit("SET_SCENARIO", { scenario: oldState })
        }
        throw e
      })
  },
  CREATE_SCENARIO(context, scenario) {
    const company_uuid = context.state.company_detail.uuid
    return createCompanyScenario(company_uuid, scenario).then(() => {
      context.dispatch("FETCH_SCENARIOS", company_uuid)
    })
  },
  CHANGE_BASE_CASE(context, scenario_uuid) {
    const company_uuid = context.state.company_detail.uuid
    return changeDefaultScenario(company_uuid, scenario_uuid).then(() => {
      context.dispatch("FETCH_SCENARIOS", company_uuid)
    })
  },
  DELETE_SCENARIO(context, scenario_uuid) {
    const company_uuid = context.state.company_detail.uuid
    const oldState = {
      ...context.state.scenarios.find((obj) => obj.uuid === scenario_uuid),
    }
    const oldActiveScenario = context.state.activeScenario

    context.commit("REMOVE_SCENARIO", { scenario_uuid })
    return deleteCompanyScenario(company_uuid, scenario_uuid).catch(
      (reason) => {
        // set deleted scenario as active scenario if the deleted scenario is the active one
        if (scenario_uuid == oldActiveScenario)
          context.state.activeScenario = oldActiveScenario
        HelmNotification(notifications.SCENARIO_DELETE_FAIL)
        context.commit("INSERT_SCENARIO", { scenario: oldState })
        // re-throw so we can use this to notify users in components
        throw reason
      }
    )
  },
  async UPDATE_DATES(context, dates) {
    context.commit("SET_DATES", { dates })
    const company_uuid = context.state.company_detail.uuid
    if (company_uuid) {
      await context.dispatch("FETCH_SCENARIOS", company_uuid).catch(() => {})
    }
  },
  CREATE_COMPANY(context, company_obj) {
    const loader = Loading.service({
      lock: true,
      text: "Creating company...",
    })

    return companyCreate(company_obj)
      .then((response) => {
        loader.close()
        context.commit("SET_COMPANY", { company: company_obj })
        // context.dispatch("organization/GET_ORGANIZATION", null, { root: true })
        context.dispatch("getCompanies")
        // return response for use in setup component
        return response
      })
      .catch((error) => {
        loader.close()
        HelmNotification(notifications.COMPANY_CREATE_FAIL)
        throw error
      })
  },
  CLOSE_CASHFLOW_TABLE(context) {
    context.commit("SET_CASHFLOW_TABLE", { cashflowTableOpen: false })
  },
  OPEN_CASHFLOW_TABLE(context) {
    context.commit("SET_CASHFLOW_TABLE", { cashflowTableOpen: true })
  },
  DROP_ACCOUNTING_CONNECTION(context) {
    const company_uuid = context.state.company_detail.uuid
    if (context.state.company_detail.data_source === DataSources.XERO) {
      return revokeXeroOAuth(company_uuid)
        .then(() => {
          context.dispatch("getCompanyDetail", company_uuid)
        })
        .catch((error) => {
          HelmNotification(notifications.DROP_ACCOUNTING_CONNECTION_FAIL)
          throw error
        })
    } else if (context.state.company_detail.data_source === DataSources.SAGE) {
      return revokeSageOAuth(company_uuid)
        .then(() => {
          context.dispatch("getCompanyDetail", company_uuid)
        })
        .catch((error) => {
          HelmNotification(notifications.DROP_ACCOUNTING_CONNECTION_FAIL)
          throw error
        })
    } else {
      return dropToken(company_uuid)
        .then(() => {
          context.dispatch("getCompanyDetail", company_uuid)
        })
        .catch((error) => {
          HelmNotification(notifications.DROP_ACCOUNTING_CONNECTION_FAIL)
          throw error
        })
    }
  },
  GET_CHART_OF_ACCOUNTS(context, company_uuid) {
    return getChartOfAccounts(company_uuid).then((response) => {
      context.commit("SET_CHART_OF_ACCOUNTS", {
        chartOfAccounts: response.data.results,
      })
    })
  },
  createContact(context, company_name) {
    const company_uuid = context.state.company_detail.uuid
    return createContact(company_uuid, company_name)
      .then((response) => {
        context.commit("SET_CHART_OF_ACCOUNTS", {
          chartOfAccounts: [...context.state.chartOfAccounts, response.data],
        })
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  createAccount(context, account_name) {
    const company_uuid = context.state.company_detail.uuid
    return createAccount(company_uuid, account_name)
      .then((response) => {
        context.commit("SET_CATEGORIES", {
          categories: [...context.state.categories, response.data],
        })
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  GET_CATEGORIES(context, company_uuid) {
    return getTransactionCategories(company_uuid).then((response) => {
      context.commit("SET_CATEGORIES", { categories: response.data.results })
    })
  },
  createInvitation(context, { email, company_uuid, permission }) {
    const inviter_uuid = context.rootState.profile.profile.id
    return createInvitation(inviter_uuid, email, company_uuid, permission)
      .then(() => {
        HelmNotification(notifications.INVITATION_CREATE_SUCCESS)
      })
      .catch((error) => {
        HelmNotification(
          notifications.INVITATION_CREATE_FAIL_USER_ALREADY_EXISTS
        )
        throw error
      })
  },
  acceptInvitation(_context, { token }) {
    return acceptInvitation(token).catch((error) => {
      HelmNotification(
        notifications.INVITATION_ACCEPT_FAIL,
        error.response.statusText
      )
      throw error
    })
  },
  getUsers(context) {
    const company_uuid = context.state.company_detail.uuid
    return new Promise((resolve, reject) => {
      getCompanyUsers(company_uuid)
        .then((response) => {
          context.commit("SET_USERS", { users: response.data })
          resolve()
        })
        .catch((error) => {
          HelmNotification(notifications.COMPANY_USERS_LOAD_FAIL)
          reject(error)
        })
    })
  },
  updateUser(context, company_user) {
    const user_uuid = company_user.user.id
    company_user.user = user_uuid
    return new Promise((resolve, reject) => {
      updateCompanyUser(company_user)
        .then(() => {
          context.dispatch("getUsers").then(() => {
            resolve()
          })
        })
        .catch((error) => {
          HelmNotification(notifications.COMPANY_USERS_UPDATE_FAIL)
          reject(error)
        })
    })
  },
  async setPaymentApprover(context, company_user_uuid) {
    const company_uuid = context.state.company_detail.uuid
    const res = await set_payment_approver(company_uuid, company_user_uuid)
    await context.dispatch("getUsers")
  },
  createCompanyUser(_context, company_user) {
    return new Promise((resolve, reject) => {
      createCompanyUser(company_user)
        .then((response) => resolve(response.data))
        .catch((error) => {
          HelmNotification(notifications.COMPANY_USERS_CREATE_FAIL)
          reject(error)
        })
    })
  },
  deleteCompanyUser(_context, company_user) {
    return new Promise((resolve, reject) => {
      deleteCompanyUser(company_user.uuid)
        .then((response) => resolve(response.data))
        .catch((error) => {
          HelmNotification(notifications.COMPANY_USERS_DELETE_FAIL)
          reject(error)
        })
    })
  },
  getUserCompaniesLegacy(context) {
    const user_uuid = context.rootState.profile.profile.id
    return getUserCompaniesLegacy(user_uuid)
      .then(({ data }) =>
        context.commit("SET_USER_COMPANIES", { companies: data })
      )
      .catch((error) => {
        HelmNotification(notifications.COMPANY_LOAD_FAIL)
        throw error
      })
  },
  validateOAuthCredentials(context, { company_uuid }) {
    return validateOAuthCredentials(company_uuid)
  },
  async updateViewBy(context, { type, viewBy }) {
    const company_uuid = context.state.company_detail.uuid
    const { type: oldType, viewBy: oldViewBy } = context.state.company_detail
    context.commit("SET_VIEW_BY", { type, viewBy })
    try {
      await updateViewBy(company_uuid, type, viewBy)
    } catch (e) {
      context.commit("SET_VIEW_BY", { type: oldType, viewBy: oldViewBy })
      throw e
    }
  },
  async updateForecastBy(context, { forecastBy, type }) {
    const company_uuid = context.state.company_detail.uuid
    const { type: oldType, forecastBy: oldForecastBy } =
      context.state.company_detail
    context.commit("SET_FORECAST_BY", { forecastBy, type })
    try {
      await updateForecastBy(company_uuid, forecastBy, type)

      await context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
        root: true,
      })
    } catch (e) {
      context.commit("SET_FORECAST_BY", {
        forecastBy: oldForecastBy,
        type: oldType,
      })
      throw e
    }
  },
}
