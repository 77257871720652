import { GET_AR, SET_AR, GET_AR_FAILURE } from "../../types"

export default {
  [GET_AR](state) {
    state.error = false
  },
  [SET_AR](state, data) {
    state.error = false
    state.accounts = data.results
  },
  [GET_AR_FAILURE](state) {
    state.error = true
  },
  SET_ACCOUNTRECEIVABLE(state, payload) {
    const index = state.accounts.findIndex((d) => d.uuid === payload.ar.uuid)
    state.accounts.splice(index, 1, payload.ar)
  },
}
