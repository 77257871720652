export default {
  SET_BANKACCOUNTS(state, { bankAccounts }) {
    state.bankAccounts = bankAccounts
  },
  SET_UPDATED_BANK_ACCOUNT(state, { bankAccount }) {
    const index = state.bankAccounts.findIndex((d) => d.id == bankAccount.id)
    state.bankAccounts.splice(index, 1, bankAccount)
  },
  SET_BULK_UPDATED_BANK_ACCOUNTS(state, { bankAccounts }) {
    bankAccounts.forEach((bankAccount) => {
      const index = state.bankAccounts.findIndex((d) => d.id == bankAccount.id)
      state.bankAccounts.splice(index, 1, bankAccount)
    })
  },
  CREATE_BANK_ACCOUNT(state, { bankAccount }) {
    state.bankAccounts.push(bankAccount)
  },
  DELETE_BANK_ACCOUNT(state, { bankAccount }) {
    const index = state.bankAccounts.findIndex((x) => x.id === bankAccount.id)
    state.bankAccounts.splice(index, 1)
  },
}
