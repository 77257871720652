import * as Sentry from "@sentry/browser"
import {
  signup,
  login,
  logout,
  signupCompanyInvite,
  signupOrganizationInvite,
  changePassword,
} from "@/api/auth"
import { REMOVE_TOKEN, SET_TOKEN, SET_FIRST_AUTH_INIT } from "@/store/types"
import localVue from "@/main"
import { parseDateToUnixSeconds } from "@/lib/utils"

const TOKEN_STORAGE_KEY = window._env_.VUE_APP_TOKEN_STORAGE_KEY

function identifyWithSentry(user) {
  if (process.env.NODE_ENV === "production") {
    if (!user) {
      Sentry.setUser()
      return
    }

    const sentryUser = {
      id: user.id,
      email: user.email,
    }
    Sentry.setUser(sentryUser)
  }
}

function identifyWithGTM(user) {
  if (process.env.NODE_ENV === "production") {
    if (!user) {
      localVue.$gtm.trackEvent({
        event: "unauthenticate_user",
        "user-id": "",
        "user-email": "",
        "user-first-name": "",
        "user-last-name": "",
        "user-level": "",
        "user-created-at": "",
        "organization-id": "",
        "organization-name": "",
        "organization-created-at": "",
      })
      return
    }

    localVue.$gtm.trackEvent({
      event: "authenticate_user",
      "user-id": user.id,
      "user-email": user.email,
      "user-first-name": user.first_name,
      "user-last-name": user.last_name,
      "user-level": user.level,
      "user-created-at": parseDateToUnixSeconds(user.date_joined),
      "organization-id": user.organization.id,
      "organization-name": user.organization.name,
      "organization-created-at": parseDateToUnixSeconds(
        user.organization.created
      ),
    })
  }
}

export default {
  signup({ commit, dispatch }, { user }) {
    return new Promise((resolve, reject) => {
      signup(user)
        .then(async (response) => {
          // user gets logged in through the backend
          commit(SET_TOKEN, response.data.auth_token)
          commit(SET_FIRST_AUTH_INIT, false)
          await dispatch("identifyWithRemotes")
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  login({ commit, dispatch }, { email, password }) {
    return new Promise((resolve, reject) => {
      login(email, password)
        .then(async (response) => {
          commit(SET_TOKEN, response.data.key)
          commit(SET_FIRST_AUTH_INIT, false)
          await dispatch("identifyWithRemotes")
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async logout({ commit, dispatch }) {
    await logout()
    commit(REMOVE_TOKEN)
    commit("subscription/RESET_STATE", null, { root: true })
    await dispatch("resetRemoteIdentity")
  },
  async initialize({ commit, dispatch, state }) {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY)

    if (token) {
      commit(SET_TOKEN, token)

      if (state.firstAuthInit) {
        commit(SET_FIRST_AUTH_INIT, false)
        await dispatch("identifyWithRemotes")
      }

      return
    } else {
      commit(REMOVE_TOKEN)
      commit("subscription/RESET_STATE", null, { root: true })
      return
    }
  },
  signupCompanyUser({ commit }, { user }) {
    return new Promise((resolve, reject) => {
      signupCompanyInvite(user)
        .then((response) => {
          commit(SET_TOKEN, response.data.auth_token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  signupOrganizationUser({ commit }, { user }) {
    return new Promise((resolve, reject) => {
      signupOrganizationInvite(user)
        .then((response) => {
          commit(SET_TOKEN, response.data.auth_token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async identifyWithRemotes({ dispatch, rootGetters }) {
    await dispatch("profile/GET_PROFILE", null, { root: true })
    const user = rootGetters["profile/userProfile"]

    if (!user.id) {
      /**
       * We need this if statement so we don't push undefined values if
       * GET_PROFILE fails for some reason.
       */
      return
    }

    identifyWithSentry(user)
    identifyWithGTM(user)
  },
  resetRemoteIdentity() {
    identifyWithSentry()
    identifyWithGTM()
  },
  changePassword(context, { old_password, new_password1, new_password2 }) {
    return changePassword(old_password, new_password1, new_password2)
  },
}
