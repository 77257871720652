import { resetAccountPassword, sendAccountPasswordResetEmail } from "@/api/auth"
import { Loading } from "element-ui"
import router from "@/router"
import HelmNotification, { notifications } from "@/lib/notification"

export default {
  resetPassword(_context, newPasswordPayload) {
    return resetAccountPassword(newPasswordPayload)
      .then(() => {
        HelmNotification(notifications.CHANGE_PASSWORD_SUCCESS)
        router.push({ name: "login" })
      })
      .catch((error) => {
        HelmNotification(notifications.CHANGE_PASSWORD_FAIL)
        throw error
      })
  },
  sendPasswordResetEmail(_context, email) {
    const loader = Loading.service({
      lock: true,
    })

    return sendAccountPasswordResetEmail(email)
      .then(() => {
        loader.close()
      })
      .catch((error) => {
        loader.close()
        throw error
      })
  },
}
