import {
  requireAuthenticated,
  requireUnauthenticated,
  requireAuthenticatedOrganizationInvite,
  requireAuthenticatedStaff,
} from "./guards"

export default [
  /*
   * User Account
   */
  {
    path: "/login",
    name: "login",
    beforeEnter: requireUnauthenticated,
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login"),
  },
  {
    path: "/signup",
    component: () => import(/* webpackChunkName: "signup" */ "@/views/Signup"),
    children: [
      {
        path: "",
        name: "signup",
        beforeEnter: requireUnauthenticated,
        meta: { stepNumber: 0 },
        component: () =>
          import(
            /* webpackChunkName: "signup-account" */ "@/views/Signup/Account"
          ),
      },
      // {
      //   path: "profile",
      //   name: "signupProfile",
      //   beforeEnter: requireAuthenticated,
      //   meta: { stepNumber: 1 },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "signup-profile" */ "@/views/Signup/Profile"
      //     ),
      // },
      {
        path: "billing",
        name: "signupBilling",
        beforeEnter: requireAuthenticated,
        meta: { stepNumber: 1 },
        component: () =>
          import(
            /* webpackChunkName: "signup-billing" */ "@/views/Signup/Billing"
          ),
      },
    ],
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    beforeEnter: requireUnauthenticated,
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "@/views/ForgotPassword"
      ),
  },
  {
    path: "/reset-password/:uid/:token",
    name: "resetPassword",
    beforeEnter: requireUnauthenticated,
    component: () =>
      import(/* webpackChunkName: "reset-password" */ "@/views/ResetPassword"),
  },
  {
    path: "/callback",
    name: "authCallback",
    component: () =>
      import(/* webpackChunkName: "oauth-callback" */ "@/views/OAuthCallback"),
  },
  {
    path: "/conversion",
    name: "conversion",
    component: () =>
      import(/* webpackChunkName: "conversion" */ "@/views/Conversion"),
  },

  /*
   * Dashboard
   */
  {
    path: "/",
    name: "dashboard",
    beforeEnter: requireAuthenticated,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard"),
  },

  /*
   * Company cash flow views
   */
  {
    path: "/company/:company",
    props: true,
    beforeEnter: requireAuthenticated,
    component: () =>
      import(/* webpackChunkName: "company-cashflow" */ "@/views/Cashflow"),
    children: [
      {
        path: "cash-flow-graphs",
        alias: "",
        name: "companyCashflow",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "cashflow-graphs" */ "@/views/Cashflow/CashflowGraphs"
          ),
      },
      {
        path: "transaction-detail",
        name: "transactionDetail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "transaction-detail" */ "@/views/Cashflow/TransactionDetail"
          ),
      },
      {
        path: "cash-flow-summary",
        name: "CashflowSummary",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "cashflow-summary" */ "@/views/Cashflow/CashflowSummary"
          ),
      },
    ],
  },

  {
    // param is optional by adding "?"
    path: "/company-create/:companyUuid?",
    beforeEnter: requireAuthenticatedStaff,
    component: () =>
      import(/* webpackChunkName: "company-create" */ "@/views/CompanyCreate"),
    children: [
      // 1. name, desc
      // 2. Xero/Quickbooks/Sage connect
      // 3. cash + credit accounts
      {
        path: "",
        name: "companySetup",
        meta: { stepNumber: 0 },
        component: () =>
          import(
            /* webpackChunkName: "company-setup" */
            "@/views/CompanyCreate/ConnectAccountingData"
          ),
      },
    ],
  },
  {
    // param is optional by adding "?"
    path: "/add-scenario/:companyUuid",
    props: true,
    beforeEnter: requireAuthenticatedStaff,
    component: () =>
      import(/* webpackChunkName: "add-scenario" */ "@/views/AddScenario"),
    children: [
      {
        path: "import",
        alias: "",
        name: "addScenarioImport",
        props: true,
        meta: { stepNumber: 0 },
        component: () =>
          import(/* webpackChunkName: "first" */ "@/views/AddScenario/Import"),
      },
      {
        path: "reserve/:scenarioUuid",
        name: "addScenarioReserve",
        props: true,
        meta: { stepNumber: 1 },
        component: () =>
          import(/* webpackChunkName: "third" */ "@/views/AddScenario/Reserve"),
      },
      {
        path: "estimates/:scenarioUuid",
        name: "addScenarioEstimates",
        props: true,
        meta: { stepNumber: 2 },
        component: () =>
          import(
            /* webpackChunkName: "second" */ "@/views/AddScenario/Estimates"
          ),
      },
    ],
  },
  {
    path: "/company-invite/:token",
    name: "companyInvite",
    beforeEnter: requireAuthenticated,
    component: () => import("@/views/Invite/CompanyInvite"),
    props: true,
  },
  {
    path: "/organization-invite/:token",
    name: "organizationInvite",
    beforeEnter: requireAuthenticatedOrganizationInvite,
    component: () => import("@/views/Invite/OrganizationInvite"),
    props: true,
  },
  {
    path: "/subscription-expired",
    name: "subscription-expired",
    component: () => import("@/views/Error/SubscriptionExpired"),
  },
  {
    path: "/quickbooks-disconnected",
    name: "quickbooks-disconnected",
    component: () => import("@/views/QuickBooksDisconnected"),
  },
  {
    path: "/reports/:report/approve-report/",
    name: "reportApproval",
    component: () => import("@/views/ReportApproval"),
  },

  /*
   * Catch 404s
   */
  {
    path: "*",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/NotFound"),
  },
]
