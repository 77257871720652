import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

const state = {
  loading: true,
  companyDrawerOpen: false,
  company_detail: {},
  companies: [],
  error: false,
  error_message: "",
  cashflowDrawerOpen: false,
  activeSideMenuTab: "none",
  cashflowTableOpen: false,
  activeScenario: null,
  scenarios: [],
  chartOfAccounts: [],
  categories: [],
  dates: [],
  users: [],
  user_companies: [],
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
