export class DataSources {
  static XERO = "xero"
  static QUICKBOOKS = "quickbooks"
  static SAGE = "sage"
}

export class Recurrences {
  static FREQUENCY = [
    { key: "Never", value: "never" },
    { key: "Daily", value: 3 },
    { key: "Weekly", value: 2 },
    { key: "Monthly", value: 1 },
    { key: "Yearly", value: 0 },
  ]
}

export const ClassTypes = [
  {
    name: "REVENUE",
    order: 1,
  },
  {
    name: "EXPENSE",
    order: 2,
  },
  {
    name: "ASSET",
    order: 3,
  },
  {
    name: "LIABILITY",
    order: 4,
  },
  {
    name: "EQUITY",
    order: 5,
  },
]

export const CompanyActionList = [
  {
    key: "OPEN_FORECAST",
    message: "Open Forecast",
    permissions: false,
    description: "Open Forecast is where you can drill down into the details, update plans, and see the big picture.",
  },
  {
    key: "MANAGE_PAYABLES",
    message: "Manage Payables",
    permissions: true,
    description: "Helm’s accounts payable tool lets you plan, see, share, and track accounts payables in minutes - integrating cash health information in real time.",
  },
  {
    key: "MANAGE_RECEIVABLES",
    message: "Manage Receivables",
    permissions: true,
    description: "Keep track of late receipts, collections notes, and risk management - lets you see cash available with certainty.",
  },
  {
    key: "MANAGE_DEBT",
    message: "Manage Debt",
    permissions: false,
    description: "Here you can check and update your bank balances used in cash management.",
  },
  {
    key: "ADD_SCENARIO",
    message: "Reset Forecast",
    permissions: true,
    description: "If you ever need to reset your forecast - update which accounts to include and how much historical records to use - you can. Don’t worry, you can save a copy of your old forecast.",
  },
]

export const ImportDataTimeframe = [
  {
    text: "12 Months (Helm default)",
    value: 12,
  },
  {
    text: "11 Months",
    value: 11,
  },
  {
    text: "10 Months",
    value: 10,
  },
  {
    text: "9 Months",
    value: 9,
  },
  {
    text: "8 Months",
    value: 8,
  },
  {
    text: "7 Months",
    value: 7,
  },
  {
    text: "6 Months",
    value: 6,
  },
  {
    text: "5 Months",
    value: 5,
  },
  {
    text: "4 Months",
    value: 4,
  },
  {
    text: "3 Months",
    value: 3,
  },
  {
    text: "2 Months",
    value: 2,
  },
  {
    text: "1 Month",
    value: 1,
  },
]

export const AgedBalanceStatuses = ["Ref to collections", "On time", "Past due"]

export const AgedBalancesDialogColumns = {
  AR: [
    {
      name: "INVOICE #",
    },
    {
      name: "DUE DATE",
    },
    {
      name: "DAYS OVERDUE",
    },
    {
      name: "AMOUNT",
      align: "right",
    },
    {
      name: "NOTES",
      width: "70",
    },
    {
      name: "STATUS",
      width: "160",
    },
    {
      name: "EXPECTED ON",
      width: "100",
    },
    {
      name: "RECEIVE ON",
      width: "156",
    },
  ],
  AP: [
    {
      name: "INVOICE #",
    },
    {
      name: "INVOICE DATE",
    },
    {
      name: "DUE",
    },
    {
      name: "AMOUNT",
      align: "right",
    },
    {
      name: "PAY ON",
      width: "156",
    },
  ],
}

export default {
  DataSources,
}
