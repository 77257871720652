import { refreshDataProviderToken } from "@/api/provider"
import {
  revokeVeemToken,
  getVeemTokens,
  getPaymentContacts,
  createPaymentContact,
  createPayment,
  deletePayment,
  deletePayments,
  approvePayments,
  updatePaymentContact,
} from "@/api/veem"

export default {
  async getVeemTokens(context) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const res = await getVeemTokens(company_uuid)
    context.commit("SET_VEEM_TOKENS", { tokens: res.data.results })
  },
  async connectVeemOAuth(context, { company_uuid }) {
    await refreshDataProviderToken(company_uuid, "veem")
  },
  revokeVeemToken(context, { company_uuid }) {
    return revokeVeemToken(company_uuid)
  },
  async getPaymentContacts(context) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const res = await getPaymentContacts(company_uuid)
    context.commit("SET_PAYMENT_CONTACTS", {
      paymentContacts: res.data.results,
    })
  },
  async createPaymentContact(context, { paymentContact }) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const res = await createPaymentContact(company_uuid, paymentContact)
    context.commit("SET_PAYMENT_CONTACT", { paymentContact: res.data })
    return res.data
  },
  async updatePaymentContact(context, { paymentContact }) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const res = await updatePaymentContact(company_uuid, paymentContact)
    context.commit("SET_PAYMENT_CONTACT", { paymentContact: res.data })
  },
  async createPayment(context, { payment }) {
    const company_uuid = context.rootState.company.company_detail.uuid
    await createPayment(company_uuid, payment)
  },
  async deletePayment(context, { payment_id }) {
    const company_uuid = context.rootState.company.company_detail.uuid
    await deletePayment(company_uuid, payment_id)
  },
  async deletePayments(context, { payment_ids }) {
    const company_uuid = context.rootState.company.company_detail.uuid
    await deletePayments(company_uuid, payment_ids)
  },
  async approvePayments(context, { payment_ids }) {
    const company_uuid = context.rootState.company.company_detail.uuid
    await approvePayments(company_uuid, payment_ids)
  },
}
