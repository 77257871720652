import { getAccountDetails, patchAccountDetails } from "@/api/auth"

export default {
  GET_PROFILE(context) {
    return getAccountDetails().then((response) => {
      context.commit("SET_PROFILE", { profile: response.data })
    })
  },
  UPDATE_PROFILE(context, profile) {
    return patchAccountDetails(profile)
      .then((response) => {
        context.commit("SET_PROFILE", { profile: response.data })
      })
      .catch((error) => {
        throw error
      })
  },
}
