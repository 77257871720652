import session from "./session"

export function getAgedBalances(company_uuid, scenario_uuid) {
  return session.get(
    `/company/${company_uuid}/scenario/${scenario_uuid}/aged-balances/`
  )
}

export function getAgedBalancesGroupedByContact(
  companyUuid,
  scenarioUuid,
  type,
  dueDate
) {
  return session.get(
    `/company/${companyUuid}/scenario/${scenarioUuid}/aged-balances/grouped_by_contact/`,
    {
      params: {
        type: type,
        due_date: dueDate,
      },
    }
  )
}

export function updateAgedBalance(company_uuid, scenario_uuid, aged_balance) {
  return session.put(
    `/company/${company_uuid}/scenario/${scenario_uuid}/aged-balances/${aged_balance.id}/`,
    aged_balance
  )
}

export function bulkUpdateAgedBalances(
  company_uuid,
  scenario_uuid,
  aged_balances
) {
  return session.put(
    `/company/${company_uuid}/scenario/${scenario_uuid}/aged-balances/multiple_update/`,
    aged_balances
  )
}

export function syncExpectedDateAgedBalances(
  company_uuid,
  scenario_uuid,
  aged_balance_type
) {
  return session.post(
    `/company/${company_uuid}/scenario/${scenario_uuid}/aged-balances/sync_expected_date/?aged_balance_type=${aged_balance_type}`
  )
}
