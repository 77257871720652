import session from "./session"

// These will return organization users
export function getUsers() {
  return session.get("/users/")
}

export function createUser(user) {
  return session.post("/users/", user)
}

export function updateUser(user) {
  if (!user.avatar) {
    delete user.avatar
  }
  return session.put(`/users/${user.id}/`, user)
}

export function deleteUser(user_uuid) {
  return session.delete(`/users/${user_uuid}/`)
}

export function createUserProfile(userProfile) {
  return session.post("/user-profile/", userProfile)
}

export function createOrganizationPromoCode(promoCode) {
  return session.post("/organization-promo-code/", promoCode)
}
