import actions from "./actions"
import mutations from "./mutations"

const state = {
  error: false,
  error_message: "",
  accounts: [],
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
