import { getUsers, updateUser, deleteUser } from "@/api/users"
import { getUserCompaniesLegacy } from "@/api/company"
import HelmNotification, { notifications } from "@/lib/notification"

export default {
  GET_USERS(context) {
    return getUsers()
      .then((response) => {
        context.commit("SET_USERS", { users: response.data.results })
      })
      .catch((error) => {
        HelmNotification(notifications.USER_LOAD_FAIL)
        throw error
      })
  },
  updateUser(context, user) {
    return new Promise((resolve, reject) => {
      updateUser(user)
        .then(() => {
          context.dispatch("GET_USERS").then(() => {
            resolve()
          })
        })
        .catch((error) => {
          HelmNotification(notifications.USER_UPDATE_FAIL)
          reject(error)
        })
    })
  },
  deleteUser(context, user) {
    return new Promise((resolve, reject) => {
      deleteUser(user.id)
        .then(() => {
          context.dispatch("GET_USERS").then(() => {
            resolve()
          })
        })
        .catch((error) => {
          HelmNotification(notifications.USER_DELETE_FAIL)
          reject(error)
        })
    })
  },
  getUserCompaniesLegacy(context, user) {
    return new Promise((resolve, reject) => {
      getUserCompaniesLegacy(user.id)
        .then((response) => resolve(response.data))
        .catch((error) => {
          HelmNotification(notifications.USER_COMPANY_LOAD_FAIL)
          reject(error)
        })
    })
  },
}
