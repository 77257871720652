import session from "@/api/session"
import { REMOVE_TOKEN, SET_TOKEN, SET_FIRST_AUTH_INIT } from "@/store/types"

const TOKEN_STORAGE_KEY = window._env_.VUE_APP_TOKEN_STORAGE_KEY

export default {
  [SET_TOKEN](state, token) {
    localStorage.setItem(TOKEN_STORAGE_KEY, token)
    session.defaults.headers.Authorization = `Token ${token}`
    state.token = token
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem(TOKEN_STORAGE_KEY)
    delete session.defaults.headers.Authorization
    state.token = null
  },
  [SET_FIRST_AUTH_INIT](state, newValue) {
    state.firstAuthInit = newValue
  },
}
