export default {
  userProfile(state) {
    return state.profile
  },
  userID(state) {
    return state.profile.id
  },
  tourCompleted(state) {
    return state.profile.tour_completed
  },
}
