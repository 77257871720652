import axios from "axios"
import store from "@/store"
import router from "@/router"
import { getCSRFToken } from "@/api/auth"
import { refreshDataProviderToken } from "@/api/provider"
import { MessageBox } from "element-ui"
import HelmNotification, { notifications } from "@/lib/notification"

const CSRF_COOKIE_NAME = "csrftoken"
const CSRF_HEADER_NAME = "X-CSRFTOKEN"
const UNSAFE_METHODS = ["post", "put", "delete", "patch"]
const ROOT_URL = window._env_.VUE_APP_ROOT_URL
export const baseURL = `${ROOT_URL}/api/v1`

function handle403Responses(error) {
  if (
    // token provided is invalid
    (error.response &&
      error.response.status === 403 &&
      error.response.data.detail === "Invalid token.") ||
    // sessionid cookie has expired, but token may be valid
    (error.response &&
      error.response.status === 403 &&
      error.response.data.detail ===
        "Authentication credentials were not provided.")
  ) {
    store.commit("auth/REMOVE_TOKEN")
    store.commit("subscription/RESET_STATE")
    // TODO redirecting here can throw an error in the vue router guards as it
    // cancells a pre-existing navigation. This seems to be harmless but we
    // should look at refactoring this logic here into the route guards.
    router.push({
      name: "login",
      query: {
        expired: "true",
      },
    })
  } else if (
    error.response &&
    error.response.status === 403 &&
    error.response.data?.code === "INVALID_SUBSCRIPTION"
  ) {
    router.push({
      name: "signupBilling",
      query: {
        redirected: true,
      },
    })
  }
}

async function handleOauthFailResponse(error) {
  let companyUuid = error.response.data.company_uuid
  let dataSource = error.response.data.data_source
  try {
    MessageBox.confirm(
      `Your company is disconnected from ${dataSource}.\nYou will be redirected to ${dataSource} for authorization.`,
      "Connection failed.",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(async () => {
        await refreshDataProviderToken(companyUuid, dataSource)
        HelmNotification(notifications.DATA_CONNECTION_SUCCESS)
      })
      .catch((_) => {
        HelmNotification(notifications.DATA_CONNECTION_FAIL)
      })
  } catch (error) {
    HelmNotification(notifications.DATA_CONNECTION_FAIL)
  }
}

// It's necessary to specify withCredentials on the global axios instance in
// ordet to get the set-cookie headers working for our CSRF setup. This affects
// axios >= 0.19.0
axios.defaults.withCredentials = true
const session = axios.create({
  baseURL,
  xsrfCookieName: CSRF_COOKIE_NAME,
  xsrfHeaderName: CSRF_HEADER_NAME,
})

session.interceptors.request.use(async (config) => {
  if (
    UNSAFE_METHODS.includes(config.method) &&
    !document.cookie.includes(config.xsrfCookieName)
  ) {
    await getCSRFToken()
  }

  return config
})

session.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.status === 403 &&
      error.response.data.code === "OAUTH_FAIL"
    ) {
      handleOauthFailResponse(error)
      return Promise.reject(error)
    }
    handle403Responses(error)
    return Promise.reject(error)
  }
)

export default session
