import axios from "axios"
import session, { baseURL } from "./session"

export function signup(user) {
  return session.post("/signup/", user)
}
export function login(email, password) {
  return session.post("/auth/login/", { email, password })
}
export function logout() {
  return session.post("/auth/logout/", {})
}
export function createAccount(email, password1, password2) {
  return session.post("/registration/", { email, password1, password2 })
}
export function changePassword(old_password, new_password1, new_password2) {
  return session.post("/auth/password/change/", {
    old_password,
    new_password1,
    new_password2,
  })
}
export function sendAccountPasswordResetEmail(email) {
  return session.post("/auth/password/reset/", { email })
}
export function resetAccountPassword({
  uid,
  token,
  new_password1,
  new_password2,
}) {
  return session.post(
    `/auth/rest-auth/password/reset/confirm/${uid}/${token}/`,
    { new_password1, new_password2, uid, token }
  )
}
export function getAccountDetails() {
  return session.get("/profile/")
}
export function patchAccountDetails(profile) {
  if (!profile.avatar) {
    delete profile.avatar
  }
  return session.put("/profile/", profile, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
export function signupCompanyInvite(user) {
  return session.post("/signup-invite/", user)
}
export function signupOrganizationInvite(user) {
  return session.post("/signup-invite-organization/", user)
}
export function getCSRFToken() {
  return axios.get(baseURL + "/auth/csrf_token/")
}

export function conversionOauthRegister() {
  return axios.get(baseURL + "/oauth/conversion/")
}
