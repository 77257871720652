import {
  getAgedBalances,
  updateAgedBalance,
  bulkUpdateAgedBalances,
} from "@/api/aged-balances"

export default {
  async getAgedBalances(context) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario
    const res = await getAgedBalances(company_uuid, scenario_uuid)
    context.commit("SET_AGED_BALANCES", { agedBalances: res.data.results })
  },
  async updateAgedBalance(context, agedBalance) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario
    const res = await updateAgedBalance(
      company_uuid,
      scenario_uuid,
      agedBalance
    )
    context.commit("SET_AGED_BALANCE", { agedBalance: res.data })
    await context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
      root: true,
    })
  },
  async bulkUpdateAgedBalances(context, agedBalances) {
    const company_uuid = context.rootState.company.company_detail.uuid
    const scenario_uuid = context.rootState.company.activeScenario

    const res = await bulkUpdateAgedBalances(
      company_uuid,
      scenario_uuid,
      agedBalances
    )

    context.commit("SET_UPDATED_AGED_BALANCES", {
      agedBalances: res.data,
    })
    await context.dispatch("company/FETCH_SCENARIOS", company_uuid, {
      root: true,
    })
  },
}
