import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const state = {
  organization: {
    accelerator_program: false,
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
