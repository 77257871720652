import sortBy from "lodash/sortBy"
import { utcToZonedTime, format } from "date-fns-tz"

export default {
  activeScenarioObject(state) {
    if (state.activeScenario && state.scenarios.length) {
      return state.scenarios.find(
        (scenario) => scenario.uuid == state.activeScenario
      )
    } else {
      return {}
    }
  },
  inactiveScenarioObjects(state) {
    return state.scenarios.filter(
      (scenario) => scenario.uuid !== state.activeScenario
    )
  },
  scenarioIsBaseCase(state, getters) {
    if (getters.activeScenarioObject.base_case) {
      return true
    }
    return false
  },
  activeScenarioLineitems(state) {
    if (!state.activeScenario || state.scenarios.length < 1) {
      return []
    }

    const activeScenario = state.scenarios.find(
      (scenario) => scenario.uuid === state.activeScenario
    )
    if (!activeScenario) {
      return []
    }

    const transactions = activeScenario.transactions
    if (!transactions) {
      return []
    }

    return transactions
  },
  filteredScenarios(state) {
    // use concat() to return copy of array instead of mutating state with sort()
    // return base_case items first
    return state.scenarios
      .concat()
      .sort((x, y) => (x.base_case === y.base_case ? 0 : x.base_case ? -1 : 1))
  },
  getEndDate(state) {
    if (state.dates.length > 0) {
      return state.dates[1]
    } else {
      return ""
    }
  },
  sortedChartOfAccounts(state) {
    return sortBy(state.chartOfAccounts, ["company_name"])
  },
  sortedCategories(state) {
    return sortBy(state.categories, ["account_name"])
  },
  getCompanyCount(state) {
    return state.user_companies.length
  },
  company_uuid(state) {
    return state.company_detail.uuid
  },
  importSource(state) {
    return state.company_detail.data_source
  },
  ranAverageDaysADRETL(state) {
    return state.company_detail.ran_average_days_adr_etl
  },
  ranAverageDaysADPETL(state) {
    return state.company_detail.ran_average_days_adp_etl
  },
  ranForecastingRuleETL(state) {
    return state.company_detail.ran_forecasting_rule_etl
  },
  todayInCompanyTimezone(state) {
    const timezone = state.company_detail.timezone
    if (!timezone) return null
    const nowUTC = new Date().toISOString()
    const today = utcToZonedTime(nowUTC, timezone)
    return format(today, "yyyy-MM-dd")
  },
}
