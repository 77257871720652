export default {
  SET_FORECASTING_RULES(state, { forecastingRules }) {
    state.forecastingRules = forecastingRules
  },
  SET_FORECASTING_RULE(state, { forecastingRule }) {
    const index = state.forecastingRules.findIndex(
      (d) => d.id === forecastingRule.id
    )
    state.forecastingRules.splice(index, 1, forecastingRule)
  },
  CREATE_FORECASTING_RULE(state, { forecastingRule }) {
    state.forecastingRules.push(forecastingRule)
  },
  DELETE_FORECASTING_RULE(state, { forecastingRule }) {
    const index = state.forecastingRules.findIndex(
      (x) => x.id === forecastingRule.id
    )
    state.forecastingRules.splice(index, 1)
  },
  BULK_DELETE_FORECASTING_RULES(state, { forecastingRules }) {
    forecastingRules.forEach((forecastingRule) => {
      const index = state.forecastingRules.findIndex(
        (d) => d.id == forecastingRule.id
      )
      state.forecastingRules.splice(index, 1)
    })
  },
  SET_PARTIAL_FORECASTING_RULES(state, { forecastingRules }) {
    forecastingRules.forEach((forecastingRule) => {
      const index = state.forecastingRules.findIndex(
        (d) => d.id == forecastingRule.id
      )
      state.forecastingRules.splice(index, 1, forecastingRule)
    })
  },
}
