import moment from "moment"
import { convertUnixtoMoment } from "@/lib/utils"

export default {
  veemConnectionStatus(state, getters) {
    if (state.veemTokens.length > 0 && getters.getTokenExpiry !== null) {
      if (moment.utc().isBefore(getters.getTokenExpiry)) {
        return true
      }
    }
    return false
  },
  getTokenExpiry(state) {
    if (state.veemTokens.length > 0) {
      return convertUnixtoMoment(state.veemTokens[0].expires_at)
    }
    return null
  },
}
