<template>
  <div v-if="isVisible" class="trial-bar">
    <div>
      Your trial ends in <strong>{{ trialDaysRemaining }} days</strong>.
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"

export default {
  name: "TrialBar",
  computed: {
    ...mapGetters("subscription", [
      "isTrial",
      "trialDaysRemaining",
      "hasActivePaymentMethod",
    ]),
    isVisible() {
      return !this.hasActivePaymentMethod && this.isTrial
    },
  },
}
</script>

<style lang="scss">
@import "@/styles/_vars.color.scss";

.trial-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: color(brand, yellow);
  font-size: 0.75rem;
  padding: 6px 0;
  min-height: 30px;
  line-height: 30px;
  z-index: 6;
  position: relative;

  strong {
    font-weight: 900;
  }

  .el-button {
    font-size: 0.75rem;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 7px 14px;
    color: color(brand, yellow);
    height: auto;
    border-radius: 4px;
    margin-left: 20px;
  }
}
</style>
