import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

const state = {
  profile: {
    id: null,
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
