import { fetchDataPercentage } from "@/api/company"

export function longPollPercentage(task_id, responseCallback) {
  return new Promise((resolve, reject) => {
    const windowInterval = setInterval(async () => {
      try {
        let intervalResponse = await fetchDataPercentage(task_id)
        if(responseCallback){
          responseCallback(intervalResponse)
        }
        if (intervalResponse?.data?.progress == 100) {
          clearInterval(windowInterval)
          resolve(intervalResponse)
        }
      } catch (error) {
        clearInterval(windowInterval)
        reject(error)
      }
    }, 1000)
  })
}